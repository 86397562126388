import React, { Component, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import "../../theme/assets/css/filepond.css";
import * as accountService from "../../services/account/accountService";
import { refreshTokenAction } from "../../redux/actions/accountActions";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import * as currentUserHelper from "../../helper/localStorage/currentUserHelper";

import { jwtDecode } from "jwt-decode";

import * as axiosAuthHelper from "../../helper/axiosHeader/axiosAuthHelper";
import * as jwtUserTokenHelper from "../../helper/localStorage/jwtUserTokenHelper";
import * as refreshTokenHelper from "../../helper/localStorage/refreshTokenHelper";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";

class EditPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            oldPass: "",
            newPass: "",
            newComfPass: "",
        };

    }


    componentDidMount() {
        store.dispatch({
            type: menuActionTypes.Setting
        });
    }


    onChangeHandle = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmitHandle = async (e) => {
        e.preventDefault();

        this.props.startLoadingAction();


        const passData = {
            NewPassword: this.state.newPass,
            ConfirmPassword: this.state.newComfPass,
            OldPassword: this.state.oldPass
        };

        const _result = await accountService.userChangePass(passData);
        if (_result.success) {
            toast.success("رمز جدید با موفقیت ثبت گردید");

            let userData = {
                refreshToken: refreshTokenHelper.getTokenFromLocalStorage()
            };
            const _result = await accountService.userLogout(userData);
            if (_result.success) {
                toast.success("با رمز جدید لاگین کنید");
            }

            refreshTokenHelper.removeTokenFromLocalStorage();
            jwtUserTokenHelper.removeTokenFromLocalStorage();
            axiosAuthHelper.removeAuthTokenFromAxiosHeader();

            this.props.finishLoadingAction();


            this.props.history.push("/");
        }
        else
            toast.error(_result.message);

        this.props.finishLoadingAction();

    };



    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>
                <div className="doctors-page">

                    <LoadingPage />


                    <div className="col-md-6 mx-auto">
                        <div className="profile-edit">

                            <form className="profile-edit-form" onSubmit={this.onSubmitHandle}>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="oldPass" name="oldPass" value={this.state.oldPass} onChange={this.onChangeHandle} placeholder="پسورد قبلی" />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="newPass" name="newPass" value={this.state.newPass} onChange={this.onChangeHandle} placeholder="پسورد جدید" />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="newComfPass" name="newComfPass" value={this.state.newComfPass} onChange={this.onChangeHandle} placeholder="تکرار پسورد" />
                                </div>

                                <input
                                    type="submit"
                                    className="btn btn-start-consult rounded "
                                    value="تغییر پسورد"
                                />
                            </form>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


// this.props.accountState.loading
// this.props.accountState.errorMessage
// this.props.accountState.isAuthenticated
// this.props.accountState.currentUser
const map_State_to_Props = state => ({
    accountState: state.accountReducer,
    loadingState: state.loadingReducer
});

// this.props.refreshTokenAction()
const map_DispatchAction_to_Props = {
    refreshTokenAction,
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(EditPassword));

import * as baseUrlAddress from "./baseUrlAddress";

export const getProfile = baseUrlAddress.getApiBaseUrl() + "/SupplyUsers/GetUser";
export const getProfileSummary = baseUrlAddress.getApiBaseUrl() + "/SupplyUsers/GetUserSummary";

export const setProfile = baseUrlAddress.getApiBaseUrl() + "/SupplyUsers/EditUser";
//export const setProfileImage = portalBaseUrl + "/Admin/Account/UploadImage";
export const setProfileImage = baseUrlAddress.getApiBaseUrl() + "/SupplyUsers/UploadImage";
export const getTimeZone = baseUrlAddress.getApiBaseUrl() + "/SupplyUsers/GetTimeZone";
export const setTimeZone = baseUrlAddress.getApiBaseUrl() + "/SupplyUsers/SetTimeZone";
export const setPushNotifClientId = baseUrlAddress.getApiBaseUrl() + "/SupplyUsers/SetPushNotifClientId";

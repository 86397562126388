import React, { Component } from "react";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import pic001 from "../../theme/assets/images/logo.png";

class AboutUs extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        store.dispatch({
            type: menuActionTypes.AboutUs
        });
    }

    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="doctors-page">
                    <div className="col-12">
                        <div className="about-wrapper">
                            <div className="about-logo">
                                <img src={pic001} />
                            </div>
                            <h4>مشاوره روانشناسی آنلاین یاری‌وی</h4>
                            <p>
                                مدت‌ها بود با دیدن هم‌وطنان مقیم خارج از کشورمان که به دنبال کمک گرفتن از مشاور و روانشناس هم‌زبان خود بودند، در فکر ایجاد بستری امن برای این عزیزان بودیم تا بتوانند به جای پرداخت هزینه‌های گزاف روانشناسان خارجی آن هم غیر هم‌زبان، با روانشناسان متخصص و متعهد داخل ایران در ارتباط باشند.
                            </p>
                            <p>
                                نهایتا در سال ۱۳۹۸ با تیمی جوان و مبتکر از فارغ‌التحصیلان دانشگاه تهران، فعالیت خود را به صورت غیررسمی در حوزه نیازسنجی ارائه خدمات مشاوره روانشناسی آنلاین بر بستر اینترنت آغاز کردیم و از ابتدای سال ۱۳۹۹ به صورت رسمی تا به امروز به فعالیت خود ادامه دادیم و بسیار خوشحالیم که توانستیم محصولی کاربردی و مطمئن، هماهنگ با نیاز کاربران و همسو با شعار محصولمان یعنی یاری‌وی، یار همیشگی شما ارائه دهیم.
                            </p>
                            <p>
                                بیشتر:
                            </p>
                            <p>
                                ما در یاری‌وی می‌خواهیم با ارائه خدمات مشاوره روانشناسی آنلاین با کیفیت در کنار قابلیت‌های نوین و مورد نیاز کاربران، امکانات سریع، ارزان و قابل دسترس برای مشاوره در اختیار همه قرار دهیم تا از کشورهای دور و نزدیک، در هر زمانی که می‌خواهند به روانشناس متخصص هم‌زبان و هم‌فرهنگ خود متصل شوند و از او یاری بگیرند. برای به حقیقت پیوستن این خواسته، دانش تخصصی بالا به همراه سخت‌کوشی را با عشق به کار همراه کردیم و خوشحالیم که توانستیم گامی در جهت ارتقای سطح سلامت روان هم‌وطنانمان برداریم.
                            </p>
                        </div>
                    </div>
                </div>

            </React.Fragment>

        );
    }
}

export default AboutUs;

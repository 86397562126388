import React, { Component } from "react";
import Media from "react-media"
import { BrowserRouter as Router, withRouter, Switch, Route, Link, Redirect } from 'react-router-dom';

import PanelAllRoutes from "./panelAllRoutes";
import AlertPage from "./alertPage";
import SidebarMenu from "../menu/sidebarMenu";
import NavbarMenu from "../menu/navbarMenu";
import Footer from "../footer/footer";
import Header from "../header/header";
//import ChatService from "../../services/cc-message/chat-service";
//import AuthService from "../../services/cc-message/auth-service";

import * as currentUserHelper from "../../helper/localStorage/currentUserHelper";

class MainLayout extends Component {
    constructor(props) {
        super(props);
        //props.xyz
        //props.selectedUsersIds
        //props.closeSelect
        //props.initRemoteStreams
        //props.setLocalStream
        //props.isActiveSelect
        //props.isActiveCall
        //props.resetState
        //props.unselectUser
        //props.selectUser
        //props.currentCall_Demand_ChatOpcId
        //props.currentCall_Supply_ChatOpcId
        //props.currentCall_Demand_UserId
        //props.currentCall_Supply_UserId
        //props.currentCall_Meeting_Id
        //props.changeMeetingParameters

        this.state = {
            toggleNavbarMenu_isOpen: false,
        };

        //console.log("main layout : " + this.props.selectedUsersIds.length)
        //console.log("main layout : " + this.props.isActiveCall)

    }

    handleFunc_toggleNavbarMenu = (checkOpenOrClose) => {
        console.warn("checkOpenOrClose : " + checkOpenOrClose);
        this.setState({ toggleNavbarMenu_isOpen: checkOpenOrClose });
    };

    //omponentDidMount() {
    async componentDidMount() {
        const _currentUser = currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings();
        if (_currentUser === null) {
            this.props.history.push("/login");
        }
        else {
            //const result = await AuthService.init();
            //if (result == false) {
            //    this.props.history.push("/login");
            //}
            //else {
            //    ChatService.setUpListeners();
            //}

        }

    }

    render() {
        return (
            <React.Fragment>

                {/* -------------- Alert-Box : for recieved message alerts  ---------------------- */}
                <AlertPage />
                {/* -------------- Alert-Box : for recieved message alerts  ---------------------- */}


                {/* -------------- NavbarMenu -------------------------------------------------- */}
                <Media
                    query="(max-width: 991px)"
                    render={() =>
                        <NavbarMenu
                            isOpen={this.state.toggleNavbarMenu_isOpen}
                            handleFunc_toggleNavbarMenu={this.handleFunc_toggleNavbarMenu}
                        />}
                />
                {/* <NavbarMenu isOpen={this.state.toggleNavbarMenu_isOpen} handleFunc_toggleNavbarMenu={this.handleFunc_toggleNavbarMenu} /> */}
                {/* -------------- NavbarMenu -------------------------------------------------- */}

                <div className="content col-xl-11 mx-auto p-0">
                    <div className="row p-0 m-0">

                        <div className="col-lg-3 d-none d-lg-block mx-auto px-0">

                            {/* -------------- SidebarMenu -------------------------------------------------- */}
                            <Media
                                query="(min-width: 992px)"
                                render={() => <SidebarMenu />}
                            />
                            {/* <SidebarMenu /> */}
                            {/* -------------- SidebarMenu -------------------------------------------------- */}

                        </div>

                        <div className="col-lg-9 col-12 p-0">

                            {/* -------------- header -------------------------------------------------- */}
                            <Header
                                {...this.props}
                                handleFunc_toggleNavbarMenu={this.handleFunc_toggleNavbarMenu}
                                toggleNavbarMenu_isOpen={this.state.toggleNavbarMenu_isOpen}
                            />
                            {/* -------------- header -------------------------------------------------- */}


                            {/* -------------- Body -------------------------------------------------- */}
                            {/* -------------- ContentPlace-Holder -------------------------------------------------- */}
                            <PanelAllRoutes
                                selectedUsersIds={this.props.selectedUsersIds}
                                closeSelect={this.props.closeSelect}
                                initRemoteStreams={this.props.initRemoteStreams}
                                setLocalStream={this.props.setLocalStream}
                                isActiveSelect={this.props.isActiveSelect}
                                isActiveCall={this.props.isActiveCall}
                                resetState={this.props.resetState}
                                unselectUser={this.props.unselectUser}
                                selectUser={this.props.selectUser}
                                currentCall_Demand_ChatOpcId={this.props.currentCall_Demand_ChatOpcId}
                                currentCall_Supply_ChatOpcId={this.props.currentCall_Supply_ChatOpcId}
                                currentCall_Demand_UserId={this.props.currentCall_Demand_UserId}
                                currentCall_Demand_UserName={this.props.currentCall_Demand_UserName}
                                currentCall_Demand_UserAvatar={this.props.currentCall_Demand_UserAvatar}
                                currentCall_Supply_UserId={this.props.currentCall_Supply_UserId}
                                currentCall_Supply_UserName={this.props.currentCall_Supply_UserName}
                                currentCall_Supply_UserAvatar={this.props.currentCall_Supply_UserAvatar}
                                currentCall_Meeting_Id={this.props.currentCall_Meeting_Id}
                                currentCall_MeetingType={this.props.currentCall_MeetingType}
                                changeMeetingParameters={this.props.changeMeetingParameters}
                            />
                            {/* -------------- ContentPlace-Holder -------------------------------------------------- */}
                            {/* -------------- Body -------------------------------------------------- */}

                        </div>

                    </div>
                </div>

                {/* -------------- Footer -------------------------------------------------- */}
                <Footer />
                {/* -------------- Footer -------------------------------------------------- */}

            </React.Fragment>
        );
    }
}

export default withRouter(MainLayout);

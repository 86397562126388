import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import store from '../../redux/store/store';

import { userLoginAction } from "../../redux/actions/accountActions";
import headerPic from "../../theme/assets/images/logo-txt-white.png";

import * as onlineService from "../../services/online/onlineService";
import * as accountService from "../../services/account/accountService";

import { toast } from "react-toastify";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingAccount from "./loadingAccount";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'; // npm uninstall node-sass >>> npm install node-sass@4.14.1 -save
import withReactContent from 'sweetalert2-react-content';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "" 
        };
    }


    onChangeHndle = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmitHandle = async (e) => {

        try {
            // if 'e' exists :
            e.preventDefault();
        }
        catch { }


        this.props.startLoadingAction();

        let userData = {
            UserName: this.state.username,
            Password: this.state.password
        };
        const isLogined = await this.props.userLoginAction(userData);
        if (isLogined) {

            const _on = await onlineService.setOnline();
            if (_on.success) {
                //toast.success("Set Online Successful");
            }

            this.props.history.push("/");
        }

        this.props.finishLoadingAction();

    };



    showMesageBox = (sender_id, body, dialog_id) => {

        window.param__sender_id = sender_id;
        window.param__body = body;
        window.param__dialog_id = dialog_id;

        const MySwal = withReactContent(Swal)
        MySwal.fire({
            title: "توافقنامه شرایط و ضوابط استفاده از سامانه مشاوره روانشناسی آنلاین یاری‌وی",
            text: ""
                + "\n\r"
                + "متن زیر توافقنامه بین سامانه مشاوره روانشناسی آنلاین یاری‌وی و شما به عنوان مشاور این سامانه می‌باشد. لطفا جهت استفاده بهینه از خدمات و سرویس‌های سامانه مشاوره روانشناسی آنلاین یاری‌وی آن را به دقت مطالعه نمایید."
                + "\n\r"
                + "استفاده از سامانه یاری‌وی، عضویت در سامانه، ثبت مشخصات و ورود به محیط سامانه یاری‌وی، استفاده از محیط کاری، ارائه مشاوره یا انجام هرگونه معامله، تعامل و یا تراکنش مالی و به طور کلی هرگونه فعالیت در سامانه یاری‌وی به معنای رویت و مطالعه دقیق این توافقنامه، آگاه بودن و پذیرفتن شرایط و اعلام موافقت خود با تمام بندهای این توافقنامه می‌باشد."
                + "\n\r"
                + "توافقنامه پیش رو حقوق متقابل و الزامات سامانه یاری‌وی و”شما“ به عنوان مشاور را در هنگام عضویت و استفاده از خدمات سامانه تعیین می‎‌کند. "
                + "\n\r"
                + "در صورتی که شما با تمام و یا بخشی از این شرایط و قوانین استفاده از سامانه موافق نیستید از سامانه یاری‌وی خارج شوید و از آن استفاده ننمایید."
                + "\n\r"
                + "حریم خصوصی"
                + "\n\r"
                + "سامانه یاری‌وی به اطلاعات خصوصی اشخاصى که از خدمات این سامانه استفاده می‌کنند، احترام گذاشته و از آن محافظت می‏‌کند."
                + "\n\r"
                + "مشاور با اطلاع از اینکه هنگام استفاده از سامانه یاری‌وی، ممکن است اطلاعاتی شخصی نظیر شماره تلفن، جنسیت، نشانی پست الکترونیکی و ... ارائه کرده و رضایت می‌دهد سامانه یاری‌وی تنها اطلاعات هویتی را در اختیار تیم پشتیبانی قرار می‌دهد، تا بتوانند درباره تشکیل جلسه و سایر خدمات مرتبط با سامانه، با شما تماس بگیرند."
                + "\n\r"
                + "سامانه یاری‌وی به هیچ عنوان اطلاعات ورود مشاوران به محیط سامانه را از طریق پیامک یا ایمیل از آنها مطالبه نخواهد نمود. "
                + "\n\r"
                + "شناسه کاربر و رمز عبور برای ورود به محیط سامانه می‌باشد و مسئولیت حفظ آن بر عهده استفاده کننده می‌باشد. در صورت مشاهده هرگونه فعالیت مشکوک در حساب کاربری خود، سریعا جهت تغییر رمز عبور خود اقدام فرمایید و سامانه یاری‌وی را از این فعالیت‌های مشکوک مطلع نمایید."
                + "\n\r"
                + "به روزرسانی سامانه"
                + "\n\r"
                + "سامانه یاری‌وی برای بهبود عملکرد خود هر زمان که صلاح بداند می‌تواند در عملکرد سامانه تغییراتی اعمال نماید. این اعمال تغییر ممکن است منجر به اختلال در عملکرد سامانه یا غیر فعال شدن سامانه برای بازه زمانی کوتاه یا بلندی شود. مشاور و کاربر با علم به امکان توقف عملکرد سامانه از خدمات این سامانه استفاده می‌نمایند و حق هرگونه اعتراض یا جبران خسارت را نسبت به آن از خود ساقط می‌نمایند."
                + "\n\r"
                + "لینک به خارج از سامانه"
                + "\n\r"
                + "در صورتی که لینکی درون هر یک از بخش‌های سامانه یاری‌وی وجود داشته باشد به معنی تایید فعالیت‌ها و محتوی آن سایت نمی‌باشد. هرگونه تراکنش مالی یا تعهدی احتمالی از سوی آن سایت بر عهده پدید آورندگان آن سایت می‌باشد و مسئولیت استفاده از خدمات آن سایت تنها متوجه شخص کاربر است."
                + "\n\r"
                + "بندهای عمومی"
                + "\n\r"
                + "مشاوران گرامی تنها مجاز به داشتن یک حساب کاربری با شماره تلفن همراه شخصی خود هستند."
                + "\n\r"
                + "هرگونه اهانت به اشخاص، مذاهب، ملیت‌ها، قومیت‌ها و غیره و هرگونه صحبت خارج از مشاوره روانشناسی ممنوع است."
                + "\n\r"
                + "سامانه یاری‌وی تنها نظرات دارای بی‌احترامی به اشخاص، مذاهب، ملیت‌ها، قومیت‌ها و غیره و صحبت‌های خارج از حیطه روانشناسی و مواردی که طبق قانون جرم می‌باشد و مواردی که خارج از حیطه کاری سامانه است را حذف می‌کند و مشاوران نمی‌توانند درخواست حذف نظرات بصورت سلیقه‌ای نمایند."
                + "\n\r"
                + "هرگونه استفاده از اطلاعات مندرج در وب سایت به طور مستقیم یا غیر مستقیم بدون کسب اجازه کتبی از مدیر سایت ممنوع بوده و پیگیرد قانونی به همراه دارد."
                + "\n\r"
                + "تمام مسئولیت تبلیغات یا متن‌های داخل سایت که از محصول، خدمات یا شرکتی غیر از یاری‌وی برعهده ارائه دهنده آن محصول و خدمات و شرکت است و یاری‌وی هیچ گونه مسئولیتی در قبال آن ندارد. طبیعتاً هرگونه خسارت‌های مالی و جانی احتمالی ناشی از استفاده از آنها برعهده ارائه دهنده آن محصول، خدمات و شرکت است."
                + "\n\r"
                + "کاربر در زمان برگزاری جلسات ویدئویی ملزم به رعایت شئونات اخلاقی و پوششی مشاوره حضوری می‌باشند."
                + "\n\r"
                + "بندهای مرتبط به مشاوران"
                + "\n\r"
                + "صحت تمامی مدارک و مستندات مشاوران از مراکز ذی‌صلاح بررسی خواهد شد و پس از تایید می‌توانند در یاری‌وی خدمات مشاوره ارائه دهند. لذا مشاوران می‌بایست مدارک و مستندات خود را در اسرع وقت به شرکت یاری‌وی ارسال کنند."
                + "\n\r"
                + "مشاوران حداقل 10 دقیقه قبل از انجام مشاوره می‌بایست آنلاین باشند و ارتباط خود را تا پایان زمان مقرر با کاربر حفظ نماید. "
                + "\n\r"
                + "ورود به پنل مشاور به منزله آنلاین بودن و آمادگی کامل برای ارائه مشاوره می‌باشد. در صورتی که تمایل به ارائه مشاوره ندارید می‌توانید دکمه ”آفلاین شو“  را کلیک کنید."
                + "\n\r"
                + "بعد از اتمام کار در پنل مشاور حتما روی دکمه خروج از حساب کاربری کلیک کنید. خارج شدن از سیستم بدون کلیک دکمه ”خروج از حساب کاربری“ به منزله غیبت شما در حالت آنلاین و طبیعتا عدم پاسخگویی شما به مراجعین می‌باشد. "
                + "\n\r"
                + "در صورت تایید درخواست رزرو مراجع از طرف مشاور، مشاور موظف است در زمان مقرر آنلاین و آماده ارائه مشاوره باشد."
                + "\n\r"
                + "با توجه حساسیت بالای سامانه یاری‌وی از حیث زمان بندی و همچنین اختلاف ساعت بین مشاور و مراجع، وقت شناسی مشاور از الزامات همکاری با سامانه یاری‌وی می‌باشد. لذا در صورت عدم رعایت وقت شناسی و تکرار آن از طرف مشاور، سامانه یاری‌وی حق اتخاذ تصمیم در رابطه با چگونگی ادامه یا قطع همکاری با مشاور را خواهد داشت."
                + "\n\r"
                + "قطع بودن انواع فیلترشکن قبل از انجام مشاوره اجباری است."
                + "\n\r"
                + "مشاور حق تجویز دارو برای کاربر را نداشته و در صورت تخلف از این بند ملزم به پاسخگویی به مراجع قضایی و انتظامی می‌باشد."
                + "\n\r"
                + "مشاور حق استفاده از اطلاعات شخصی کاربر به هر منظوری را ندارد و در صورت تخلف ملزم به پاسخگوی به مراجع ذی صلاح می‌باشد."
                + "\n\r"
                + "مشاور حق استفاده از اطلاعات شخصی کاربر به هر منظوری را ندارد و در صورت تخلف ملزم به پاسخگوی به مراجع ذی صلاح می‌باشد."
                + "\n\r"
                + "هرگونه فعالیت تبلیغاتی اعم از مرتبط و غیر مرتبط با فعالیت‌های مشاوران و کاربران در یاری‌وی ممنوع است."
                + "\n\r"
                + "هرگونه ارتباط‌گیری مشاور و کاربر به منظور مشاوره یا پرداخت هزینه از طرف کاربر، خارج از سایت یاری‌وی ممنوع بوده و در صورت مشاهده موردی ازاین قبیل به مشاور تذکر و در صورت تکرار مسدود‌سازی اکانت مشاور متخلف اجرا خواهد شد ."
                + "\n\r"
                + "درخواست تسویه حساب به شماره حساب یا شبای مشاور با تایید هویت هر هفته یک بار انجام خواهد ‌شد ."
                + "\n\r"
                + "یاری‌وی هیچ‌گونه مسئولیتی در قبال کلیه توصیه‌ها و مشاوره مشاور ندارد و تمامی مسئولیت‌‌های ذکر شده بر عهده مشاور می‌باشد."
                + "\n\r"
                + " کلیه کسور قانونی اعم از بیمه، مالیات و سایر به عهده مشاور می‌باشد و یاری‌وی هیچ‌گونه مسئولیتی از این بابت نخواهد داشت."
                + "\n\r"
                + "تغییر شرایط و ضوابط"
                + "\n\r"
                + "سامانه یاری‌وی می‌تواند در هر زمان تمام یا بخشی از شرایط و قوانین استفاده از سامانه را تغییر دهد. تغییرات ایجاد شده فورا در همین صفحه منتشر و به‌روزرسانی می‌شود و به کاربران و مشاوران اطلاع داده می‌شود. ادامه استفاده از سامانه یاری‌وی به منزله مطالعه این صفحه، آگاهی از شرایط جدید و قبول آن می‌باشد."
                + "\n\r"
                + "شرایط و قوانین موجود در این صفحه آخرین تغییرات مربوط به توافقنامه استفاده از سامانه یاری‌وی بوده و جایگزین کلیه توافقات قبلی می‌باشد. سامانه یاری‌وی پیشنهاد می‌کند یک نسخه چاپی یا الکترونیکی از این توافق نامه را برای ارجاعات بعدی نزد خود نگه دارید."
                + "\n\r",
            icon: 'success',
            showCancelButton: false,
            iconColor: '#ffce46',
            confirmButtonColor: '#489fa4',
            cancelButtonColor: '#727272',
            confirmButtonText: 'قوانین را میپذیرم',
            cancelButtonText: 'فعلا نه',
            allowOutsideClick: false,
            allowEscapeKey: false,
        })
            .then((result) => {
                if (result.isConfirmed) {

                    (async () => {

                        let userData = {
                            UserName: this.state.username,
                            Password: this.state.password
                        };
                        const _accepted = await accountService.acceptRules(userData);
                        if (_accepted.success) {

                            this.onSubmitHandle();

                        }

                    })();

                }
            })

    };


    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        return (
            <React.Fragment>
                <LoadingAccount />

                {(this.props.accountState.errorMessage === "مشاور گرامی ابتدا باید قوانین و مقررات را مطالعه و قبول نمایید") &&
                    this.showMesageBox()
                }

                <div className="login-page">
                    <div className="login-header">
                        <div className="bg-logo">
                            <img src={headerPic} className="logo-login" />
                        </div>
                    </div>
                    <div className="content col-md-10 col-lg-7 col-xl-5 mx-auto p-0">
                        <div className="login-container">
                            <form className="w-100" onSubmit={this.onSubmitHandle}>
                                <div className="form-group">
                                    <div className="cap-focus">
                                        <input onChange={this.onChangeHndle} value={this.state.username} id="username" name="username" type="text" className="form-control login-input" placeholder="نام کاربری" />
                                    </div>
                                </div>
                                <div className="form-group mb-2">
                                    <div className="cap-focus">
                                        <input onChange={this.onChangeHndle} value={this.state.password} id="password" name="password" type="password" autoComplete="new-password" autocomplete="new-password" className="form-control login-input" placeholder="رمز عبور" />
                                    </div>
                                    <div className="w-100 d-flex justify-content-end">
                                        <Link to={"/forgot-password"} className="forgot-pass">رمز عبور خود را فراموش کرده ام</Link>
                                    </div>
                                </div>

                                { /* 
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                                    <label className="custom-control-label" htmlFor="customSwitch1">مرا به خاطر داشته باش</label>

                                </div>
                                */}

                                <button type="submit" className="btn btn-load-effect btn-sign-in">
                                    ورود
                                </button>

                                {/*{this.props.accountState.loading
                                    ?
                                    (
                                        <div className="alert alert-info">Waiting ...</div>
                                    )
                                    :
                                    (
                                        <div></div>
                                    )
                                }*/}

                                {/*{this.props.accountState.errorMessage
                                    &&
                                    <div className="alert alert-warning">{this.props.accountState.errorMessage}</div>
                                }*/}

                                {/*<div className="d-flex w-100 justify-content-center">
                                    <Link to={"/register"} className="btn btn-link" type="link">
                                        هنوز نام نکردید؟
                                        <span>
                                            ثبت نام
                                        </span>
                                    </Link>
                                </div>*/}
                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

// this.props.accountState.loading
// this.props.accountState.errorMessage
// this.props.accountState.isAuthenticated
// this.props.accountState.currentUser
const map_State_to_Props = state => ({
    accountState: state.accountReducer
});

// this.props.userLoginAction()
const map_DispatchAction_to_Props = {
    userLoginAction,
    startLoadingAction,
    finishLoadingAction
};

export default connect(map_State_to_Props, map_DispatchAction_to_Props)(Login);

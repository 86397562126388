import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as meetingResavationService from "../../services/meeting/meetingResavationService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";
import convertUrltoEncode from "../../helper/validation/convertUrltoEncode";



class MeetingsReserveArchive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            meetings: [],
            reserves: [],
            pageSize: 100,
            currentPage: 1,
            intervalId: null
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        store.dispatch({
            type: menuActionTypes.MeetingsRejectedReserveArchive
        });

        this.props.startLoadingAction();


        const _List = await meetingResavationService.getRejectedList();
        if (_List.success === true)
            if (_List.data !== null)
                this.setState({ reserves: _List.data });

        this.props.finishLoadingAction();


    }

    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="doctors-page">

                    <LoadingPage />


                    <div className="row p-0 m-0">


                        {this.state.reserves.length === 0
                            ?
                            (
                                <>
                                    <div className="col-12 mx-auto">
                                        <br />
                                        <center> در حال حاضر جلسه‌ی رزرو رد شده وجود ندارد </center>
                                        <br />
                                    </div>
                                </>
                            )
                            :
                            (
                                <>

                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                    {this.state.reserves.map(item => (

                                        <div className="col-xl-4 col-sm-6 px-2" key={item.id}>
                                            <div className="notif-widget meetings-reserve-archive-card" style={{ borderBottom: "3px solid #e04b59" }} >
                                                {/*<div className="reserve-type-icon-big">*/}
                                                {/*    {(item.meetingType === 0) &&*/}
                                                {/*        <i className="fas fa-comments" style={{ color: "#ff000012" }} ></i>*/}
                                                {/*    }*/}
                                                {/*    {(item.meetingType === 1) &&*/}
                                                {/*        <i className="fas fa-phone-volume" style={{ color: "#ff000012" }} ></i>*/}
                                                {/*    }*/}
                                                {/*    {(item.meetingType === 2) &&*/}
                                                {/*        <i className="fas fa-video" style={{ color: "#ff000012" }} ></i>*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                                <div className="row p-0 m-0">
                                                    <div className="col-12 p-0">
                                                        <div className="row p-0 m-0">
                                                            <div className="col-3 p-0 d-flex align-items-center">
                                                                <div className="user-image" style={{ backgroundImage: "url(" + baseUrlAddress.getFilesBaseUrl() + convertUrltoEncode(item.demand_Avatar) + ")" }}>
                                                                </div>
                                                            </div>
                                                            <div className="col-9 p-0 d-flex align-items-center">
                                                                <div className="w-100 notif-name">
                                                                    <h2>{item.demand_UserName}</h2>
                                                                    <p>
                                                                        مراجع
                                                        </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 p-0 mt-1">
                                                        <div className="reserve-offer">
                                                            <div className="reserve-offer-body">
                                                                <ul className="px-1">
                                                            {/*        <li>*/}
                                                            {/*            نوع جلسه :*/}
                                                            {/*<span>*/}
                                                            {/*                {(item.meetingType === 0) &&*/}
                                                            {/*                    <span>*/}
                                                            {/*                        &nbsp;*/}
                                                            {/*                        چت*/}
                                                            {/*                        &nbsp;*/}
                                                            {/*        </span>*/}
                                                            {/*                }*/}
                                                            {/*                {(item.meetingType === 1) &&*/}
                                                            {/*                    <span>*/}
                                                            {/*                        &nbsp;*/}
                                                            {/*                        تماس صوتی*/}
                                                            {/*                        &nbsp;*/}
                                                            {/*        </span>*/}
                                                            {/*                }*/}
                                                            {/*                {(item.meetingType === 2) &&*/}
                                                            {/*                    <span>*/}
                                                            {/*                        &nbsp;*/}
                                                            {/*                        تماس  تصویری*/}
                                                            {/*                        &nbsp;*/}
                                                            {/*        </span>*/}
                                                            {/*                }*/}
                                                            {/*            </span>*/}
                                                            {/*        </li>*/}

                                                                    <li>
                                                                        موضوع :
                                                            <span>
                                                                            &nbsp;
                                                                {item.consultantTypeName}
                                                                &nbsp;
                                                            </span>
                                                                    </li>
                                                                    <li>
                                                                        روز پیشنهادی :
                                                            <span>
                                                                            &nbsp;
                                                                {item.start_Proposal_FullDate_InLocal_Shamsi}
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        زمان پیشنهادی :
                                                            <span>
                                                                            &nbsp;
                                                                {" " + item.start_Proposal_FromHour_InLocal + " "}
                                                                &nbsp;
                                                                الی
                                                                &nbsp;
                                                                {" " + item.start_Proposal_ToHour_InLocal + " "}
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        وضعیت :
                                                            <span>
                                                                            &nbsp;
                                                                {(item.status === 0) &&
                                                                                <span>
                                                                                در انتظار تایید مشاور 
                                                                    </span>
                                                                            }
                                                                            {(item.status === 1) &&
                                                                                <span>
                                                                                    پذیرفته شده توسط مشاور
                                                                    </span>
                                                                            }
                                                                            {(item.status === 2) &&
                                                                                <span>
                                                                                    رد شده توسط مشاور - در جهت حفظ رضایت‌مندی مراجع از شما، خواهشمندیم جلسات رد شده توسط خودتان را به حداقل رسانید
                                                                    </span>
                                                                            }
                                                                            {(item.status === 3) &&
                                                                                <span>
                                                                                    در حال بررسی توسط کاربر
                                                                    </span>
                                                                            }
                                                                            {(item.status === 4) &&
                                                                                <span>
                                                                                    پذیرفته شده توسط کاربر
                                                                    </span>
                                                                            }
                                                                            {(item.status === 5) &&
                                                                                <span>
                                                                                    حذف شده توسط کاربر
                                                                    </span>
                                                                            }
                                                                &nbsp;
                                                            </span>
                                                                    </li>
                                                                    
                                                                    <li>
                                                                        شناسه درخواست رزرو :
                                                            <span>
                                                                            &nbsp;
                                                                {item.id}
                                                                &nbsp;
                                                            </span>
                                                                    </li>
                                                                    
                                                                </ul>
                                                                <div className="w-100">
                                                                    <div className="row p-0 m-0">
                                                                        <div className="col-12 px-1">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    ))}
                                    { /* ----------- key={item._id} must be for list in react ---------------- */}

                                </>
                            )
                        }



                    </div>
                </div >

            </React.Fragment >
        );
    }
}


// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(MeetingsReserveArchive));

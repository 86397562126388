import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { toast } from "react-toastify";

import * as transactionService from "../../services/transaction/transactionService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";

class Transaction extends Component {
    constructor(props) {
        super(props);

        // برای بار اولی که اپ بالا می آید
        this.state = {
            transactions: [],
            pageSize: 10,
            currentPage: 1,
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        this.props.startLoadingAction();


        const t_List = await transactionService.getTransactionList();
        if (t_List.success === true)
            if (t_List.data !== null)
                this.setState({ transactions: t_List.data });

        this.props.finishLoadingAction();


        store.dispatch({
            type: menuActionTypes.Transaction
        });

    }

    checkoutRequest = async (e) => {
        e.preventDefault();

        this.props.startLoadingAction();


        const res = await transactionService.checkoutRequest();
        if (res.success === true) {
            toast.success(res.message);

            const t_List = await transactionService.getTransactionList();
            if (t_List.success === true)
                if (t_List.data !== null)
                    this.setState({ transactions: t_List.data });
        }
        else {
            toast.error(res.message);
        }

        this.props.finishLoadingAction();


    }


    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>
                <div className="doctor-single-page-wrapper w-100">

                    <LoadingPage />


                    <div class="wallet-page">

                        <div class="row p-0 m-0">
                            <div class="col-md-12 px-2 mb-2">

                                <div class="transaction-wrapper">

                                    <main className="dashboard">
                                        <div className="row p-0 m-0">
                                            <div className="col-12 p-0">
                                                <div className="row p-0 m-0">
                                                    <div class="col-12 px-0 px-md-2">
                                                        <div class="card alert" style={{ marginBottom: "0px", marginTop: "10px" }}>
                                                            <div class="row p-0 m-0 w-100">
                                                                <div class="col-md-10 px-0 d-flex align-items-center justify-content-center justify-content-md-start">
                                                                    <span>
                                                                        شما می توانید با زدن دکمه روبرو درخواست تسویه حساب کنید تا در اولین سیکل آینده مجموع درآمد شما از برگزاری جلسات تسویه گردد
                                                                    </span>
                                                                </div>
                                                                <div class="col-md-2 px-0 d-flex align-items-end justify-content-md-end justify-content-center mt-2 mt-md-0">
                                                                    <button class="btn btn-reserve-offline rounded w-unset" onClick={(e) => this.checkoutRequest(e)}>
                                                                        درخواست تسویه
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </main>

                                    <p class="transaction-head">
                                    </p>



                                    {this.state.transactions.length === 0
                                        ?
                                        (
                                            <>
                                                <div className="col-12 mx-auto">
                                                    <br />
                                                    <center > هیچ رکورد مالی وجود ندارد </center>
                                                    <br />
                                                </div>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <ul class="transaction-list">
                                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                                    {this.state.transactions.map(item => (
                                                        <li key={item.id}>
                                                            <p>
                                                                {item.description}

                                                                {(item.isCheckout)
                                                                    ?
                                                                    (
                                                                        <span style={{ color: "blue", padding: "0 10px" }}>
                                                                            (تسویه شده)
                                                                        </span>
                                                                    )
                                                                    :
                                                                    (
                                                                        <span>
                                                                            {(item.isRequestTo_Checkout)
                                                                                ?
                                                                                (
                                                                                    <span style={{ color: "orange", padding: "0 10px" }}>
                                                                                        (درخواست تسویه)
                                                                                    </span>
                                                                                )
                                                                                :
                                                                                (
                                                                                    <span style={{ color: "black", padding: "0 10px" }}>
                                                                                        (آماده درخواست)
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        </span>
                                                                    )
                                                                }

                                                                <span class="added" style={{ fontWeight: "500", fontSize: "15px", direction: "rtl", textAlign: "right", display: "inline-block", fontFamily: "iranyekan" }}>
                                                                    {item.amount}
                                                        &nbsp;
                                                        تومان
                                                    </span>

                                                            </p>
                                                            <div class="transaction-date">
                                                                {item.createDateTime_InLocal_Shamsi}
                                                            </div>
                                                        </li>
                                                    ))}
                                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                                </ul>
                                            </>
                                        )
                                    }



                                   


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(Transaction));

import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { toast } from "react-toastify";

import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import * as fileRecordService from "../../services/fileRecord/fileRecordService";
import * as meetingService from "../../services/meeting/meetingService";

import "../../theme/global/fileRecord.css";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import m002 from "../../theme/assets/images/m2.jpg";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";

class FileRecord extends Component {
    constructor(props) {
        super(props);

        // برای بار اولی که اپ بالا می آید
        this.state = {
            Demand_UserId: "0",
            Type: "text",
            Value: "",
            records: [],
            pageSize: 9,
            currentPage: 1,
            meetings: [],
        };

    }


    async componentDidMount() {

        // nid == Demand_UserId
        let nid = this.props.match.params.nid; // nid is string
        if (nid === undefined || nid === "undefined" || nid === "0")
            this.props.history.push("/not-found");

        this.setState({ Demand_UserId: nid });



        const req__Data = { Demand_UserId: nid + "" };
        const _List = await meetingService.getList(req__Data);
        if (_List.success === true)
            if (_List.data !== null)
                this.setState({ meetings: _List.data });



        await this.loadRecordsList(nid);



        store.dispatch({
            type: menuActionTypes.FileRecord
        });

    }

    loadRecordsList = async (Demand_UserId) => {

        this.props.startLoadingAction();





        const reqData = { Demand_UserId: Demand_UserId + "" };
        const _obj = await fileRecordService.getList(reqData);
        if (_obj.success === true) {
            if (_obj.data !== null) {
                this.setState({ records: _obj.data });
            }
        }


        this.props.finishLoadingAction();


    }


    onChangeHandle = e => {
        this.setState({ [e.target.name]: e.target.value });
    };


    onSubmitHandle = async (e) => {
        e.preventDefault();


        this.props.startLoadingAction();

        const reqData = {
            Demand_UserId: this.state.Demand_UserId + "",
            Type: this.state.Type + "",
            Value: this.state.Value + ""
        };
        const _result = await fileRecordService.saveRecord(reqData);
        if (_result.success === true) {
            await this.loadRecordsList(this.state.Demand_UserId);
            toast.success("مطلب مورد نظر با موفقیت در پرونده ثبت گردید");
            this.setState({ Type: "text" });
            this.setState({ Value: "" });
        }
        else {
            toast.error(_result.message);
        }

        this.props.finishLoadingAction();


    };



    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>
                <div className="doctors-page">

                    <LoadingPage />


                    <div className="row m-0 p-0">
                        <div className="col-md-6 col-12">
                            <div className="patient-case-area pat-page w-100 p-2">
                                <div id="pateint-case-wrapper" >
                                    <div className="noteing-wrapper">
                                        <div className="noteing-body">
                                            <div className="notes-right-wrapper">

                                                { /* ----------- key={item._id} must be for list in react ---------------- */}
                                                {this.state.records.map(item => (
                                                    <div key={item.id}>
                                                        {(item.type === "text") &&
                                                            <div className="note-right" style={{ flexDirection: "column", width: "100%" }}>
                                                                <div style={{ textAlign: "right" }}>
                                                                    {item.value}
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                    {item.createDate_String}
                                                                </div>
                                                            </div>
                                                        }
                                                        {(item.type === "voice") &&
                                                            <div className="note-right">
                                                                <audio
                                                                    controls
                                                                    src={item.value}
                                                                />
                                                                {item.createDate_String}
                                                            </div>
                                                        }
                                                    </div>
                                                ))}
                                                { /* ----------- key={item._id} must be for list in react ---------------- */}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="noteing-create">

                                        <form onSubmit={this.onSubmitHandle}>
                                            <div className="form-group position-relative m-0">
                                                <textarea
                                                    className="form-control chat-textbox"
                                                    placeholder="یادداشت کنید..."
                                                    rows="1"
                                                    name="Value"
                                                    value={this.state.Value}
                                                    onChange={this.onChangeHandle}
                                                >
                                                </textarea>
                                                <button type="submit" className="btn btn-send-pm">
                                                    <i className="fas fa-paper-plane"></i>
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">

                            <div className="row p-2">

                                {this.state.meetings.length === 0
                                    ?
                                    (
                                        <>
                                            <div className="col-12 mx-auto">
                                                <br />
                                                <center > در حال حاضر جلسه‌ای در آرشیو وجود ندارد </center>
                                                <br />
                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            { /* ----------- key={item._id} must be for list in react ---------------- */}
                                            {
                                                this.state.meetings.map(item => (
                                                    <div className="col-6 px-2" key={item.id}>
                                                        <div className="archive-widget">
                                                            <div className="row p-0 m-0">
                                                                <div className="col-5 user-side">
                                                                    <div>
                                                                        <img src={baseUrlAddress.getFilesBaseUrl() + item.demand_Avatar} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-7 user-side">
                                                                    <div>
                                                                        <h4>{item.demand_UserName}</h4>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 archive-detail">
                                                                    <div className="row p-0 m-0">
                                                                        <div className="col-12 px-2 detail-it-in-archive">
                                                                            <p>
                                                                                کد جلسه
                                                                                :
                                                                                {item.id}
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-12 px-2 detail-it-in-archive">
                                                                            <p>
                                                                                موضوع
                                                                                :
                                                                                {item.consultantTypeName}
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-12 px-2 detail-it-in-archive">
                                                                            <p>
                                                                                تاریخ جلسه
                                                                                :
                                                                                {item.proposaled_Start_DateTime_String_Shamsi}
                                                                            </p>
                                                                        </div>
                                                                        {/*<div className="col-12 px-2 detail-it-in-archive">*/}
                                                                        {/*    <p>*/}
                                                                        {/*        مدت زمان*/}
                                                                        {/*        :*/}
                                                                        {/*        {item.timeDuration_InMinute}*/}
                                                                        {/*        &nbsp;*/}
                                                                        {/*        دقیقه*/}
                                                                        {/*    </p>*/}
                                                                        {/*</div>*/}
                                                                        <div className="col-12 px-2 detail-it-in-archive">
                                                                            <p>
                                                                                حق مشاوره
                                                                                :
                                                                                <span>
                                                                                    {(item.timeDuration_InMinute === 0)
                                                                                        ?
                                                                                        (
                                                                                            <span>
                                                                                                0
                                                                                                تومان
                                                                                            </span>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <span>
                                                                                                {(item.costDuration_InDollar === 0)
                                                                                                    ?
                                                                                                    (
                                                                                                        <span>در حال پردازش</span>
                                                                                                    )
                                                                                                    :
                                                                                                    (
                                                                                                        <span>
                                                                                                            {item.costDuration_InDollar}
                                                                                                            &nbsp;
                                                                                                            تومان
                                                                                                        </span>
                                                                                                    )
                                                                                                }
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                </span>
                                                                            </p>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            { /* ----------- key={item._id} must be for list in react ---------------- */}
                                        </>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(FileRecord));

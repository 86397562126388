import * as axiosRequest from "../../helper/axiosRequest/httpRequest";
import * as onlineUrlAddress from "../../utils/urlAddress/onlineUrlAddress";

export async function checkOnline() {
    return await axiosRequest.HttpPost(onlineUrlAddress.checkOnline, null);
}

export async function setOnline() {
    return await axiosRequest.HttpPost(onlineUrlAddress.setOnline, null);
}

export async function setOffline() {
    return await axiosRequest.HttpPost(onlineUrlAddress.setOffline, null);
}
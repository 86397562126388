// ------------------------------------------------------------------------------------------------
// Load from theme assets :
import "./theme/global/lib/font-awesome/5.13.0/css/all.css";
import "./theme/assets/css/bootstrap.min.css";
import "./theme/assets/font/font.css";
import "./theme/assets/mmenu/css/mmenu.css";
import "./theme/assets/css/core-style.css";
import "./theme/assets/css/custom-kbh.css";
// ------------------------------------------------------------------------------------------------

import "react-toastify/dist/ReactToastify.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./redux/store/store";
//import * as serviceWorker from "./sw-default";
import Pushe from "pushe-webpush";
import App from "./App";

import TagManager from 'react-gtm-module'


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
    ,
    document.getElementById("root")
);

// To work offline and load faster 'register()' in "Production" :
//serviceWorker.register();



// Register ServiceWorker :
// Not Neccessery when Use Pushe.init("app_id") , It will Register Automatically :
// -------------------------------------------------------------------------------------
//if ("serviceWorker" in navigator) {
//    const swUrl = `${process.env.PUBLIC_URL}/pushe-sw.js`;
//    navigator.serviceWorker
//        .register(swUrl)
//        .then(reg => {
//            console.log("Service worker registred successfully ", reg);
//        })
//        .catch(err => {
//            console.log("Service worker not registred !! failed with ", err);
//        });
//}
// -------------------------------------------------------------------------------------
// Pushe Service :
Pushe.init("5dn6j057lrk04oke");
Pushe.subscribe({
    "showDialog": true,
    "icon": "https://files.pushe.co/prompt-icons/2021/07/20210713-0fd6994bfa4a4bb69c43198c209b9993.png",
    "title": "عضویت در اعلانات پنل مشاوران",
    "content": "جهت یادآوری و برقرراری جلسات مشاوره و همه اطلاع رسانی های مورد نیاز پنل مشاوره",
    "position": "top-center",
    "direction": "rtl",
    "acceptText": "قبول می کنم",
    "rejectText": "تمایلی ندارم",
    "promptTheme": "pushe-prompt-theme2",
    "mobilePosition": "top",
    "dialogRetryRate": 86400
});
//Pushe.unsubscribe(); // لغو عضویت



//const tagManagerArgs = {
//    gtmId: 'GTM-MSSBL9W'
//}

//TagManager.initialize(tagManagerArgs);





import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import * as profileService from "../../services/profile/profileService";
import Pushe from "pushe-webpush";

import * as meetingService from '../../services/meeting/meetingService';

import Pic001 from "../../theme/assets/images/icon/chat.png";
import Pic002 from "../../theme/assets/images/icon/team.png";
import Pic003 from "../../theme/assets/images/icon/giftbox.png";
import Pic004 from "../../theme/assets/images/icon/test.png";
import Pic005 from "../../theme/assets/images/icon/magazine.png";
import Pic006 from "../../theme/assets/images/icon/headset.png";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import "../../theme/assets/css/dashboard-style.css";
import "../../theme/assets/css/owl.carousel.css";
import "../../theme/assets/css/owl.theme.default.css";

import NotificationBreifList from '../dashboard/notificationBreifList';
import ReserveArchiveBriefList from '../meeting/reserveArchiveBriefList';
import UserSummary from '../dashboard/userSummary';

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMeetNow: false,
        };
    }

    async componentDidMount() {

        store.dispatch({
            type: menuActionTypes.Dashboard
        });

        this.props.startLoadingAction();
        setTimeout(() => { this.props.finishLoadingAction(); }, 4000);

        Pushe.getDeviceId()
            .then(function (deviceId) {
                (async () => {
                    const userData = {
                        PushNotif_UserId: deviceId
                    };
                    const _result = await profileService.setPushNotifClientId(userData);
                    if (_result.success) {
                        //toast.success(_result.message);
                    }
                    else {
                        toast.error(_result.message);
                    }
                })();
            });


        const ___res = await meetingService.checkAllIsMeeting();
        if (___res.success === true) {
            if (___res.data.result) {
                this.setState({ isMeetNow: true })
            }
        }



    }

    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>


                <div className="doctors-page">

                    <LoadingPage />

                    <main className="dashboard">

                        {
                            this.state.isMeetNow &&
                            (
                                <div className="row p-0 m-0">
                                    <div className="col-12 p-0">
                                        <div className="row p-0 m-0">
                                            <div class="col-12 px-0 px-md-2">
                                                <div class="card alert">
                                                    <div class="row p-0 m-0 w-100">
                                                        <div class="col-md-10 px-0 d-flex align-items-center justify-content-center justify-content-md-start">
                                                            <span>
                                                                در حال حاضر یک جلسه فعال دارید که می‌توانید با کلیک روی شروع، اطلاعات جلسه را مشاهده کنید
                                                    </span>
                                                        </div>
                                                        <div class="col-md-2 px-0 d-flex align-items-end justify-content-md-end justify-content-center mt-2 mt-md-0">
                                                            <Link class="btn btn-reserve-offline rounded w-unset" to={"/meetings-reserve-archive"}>
                                                                شروع
                                                    </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        <UserSummary />

                        <div className="row p-0 m-0">

                            <div class="col-12 p-0">
                                <div class="top">
                                    <div class="row p-0 m-0">

                                        <div class="col-lg-12 px-0 px-md-2 mb-3 mb-md-0">
                                            <div class="upcoming-appointments card h-100">
                                                <div className="card-header">
                                                    <h1>درخواست های مشاوره</h1>
                                                </div>
                                                <div class="card-body d-flex align-items-center justify-content-center">

                                                    <ReserveArchiveBriefList />

                                                </div>
                                                <div className="more"><Link to="/meetings-reserve-archive">مشاهده همه</Link></div>
                                            </div>
                                        </div>


                                        <div class="col-12 px-0 px-md-2">
                                            <div className="follow-us-on-social">
                                                <p>
                                                    ما را در شبکه های اجتماعی دنبال کنید.
                                                </p>
                                                <div className="col-12 p-0 effect aeneas">
                                                    <div className="buttons">
                                                        {/*<a href="#" className="fb" title="Join us on Facebook"><i className="fab fa-facebook" aria-hidden="true"></i></a>*/}
                                                        {/*<a href="#" className="pinterest" title="Join us on Pinterest"><i className="fab fa-youtube" aria-hidden="true"></i></a>*/}
                                                        <a href="https://www.instagram.com/yariwaycom/"
                                                            className="insta" ><i
                                                                className="fab fa-instagram" aria-hidden="true"></i></a>
                                                        <a href="https://t.me/yariwaycom" className="tw"><i
                                                            className="fab fa-telegram-plane"
                                                            aria-hidden="true"></i></a>
                                                        <a href="https://www.youtube.com/channel/UCxkahgOTKrLN85D3eqNwAuA"
                                                            className="pinterest" ><i
                                                                className="fab fa-youtube" aria-hidden="true"></i></a>
                                                        <a href="https://www.linkedin.com/company/yariway/"
                                                            className="in" ><i
                                                                className="fab fa-linkedin" aria-hidden="true"></i></a>
                                                        <a href="https://castbox.fm/channel/id5489513?utm_source=podcaster&utm_medium=dlink&utm_campaign=c_5489513&utm_content=YariWay%20l%20%DB%8C%D8%A7%D8%B1%DB%8C%20%D9%88%DB%8C-CastBox_FM"
                                                            className="cast" ><i
                                                                className="fas fa-headphones" aria-hidden="true"></i></a>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </main>
                </div>


            </React.Fragment>
        );
    }
}

// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(Dashboard));

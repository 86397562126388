import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as meetingService from "../../services/meeting/meetingService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

import m002 from "../../theme/assets/images/m2.jpg";
//import chatService from "../../services/cc-message/chat-service";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import UserSummary from '../dashboard/userSummary';

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";


class MeetingsArchive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            meetings: [],
            pageSize: 100,
            currentPage: 1
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        store.dispatch({
            type: menuActionTypes.MeetingsArchive
        });

        this.props.startLoadingAction();


        const _List = await meetingService.getList();
        if (_List.success === true)
            if (_List.data !== null)
                this.setState({ meetings: _List.data });

        this.props.finishLoadingAction();


    }

    handleGotoChat = async (_id) => {

        //this.props.startLoadingAction();

        ////const diaglog = await chatService.fetchDialogsById(_id);
        //try {
        //    const diaglog = await chatService.fetchDialogs_Really_ById_V2(_id);
        //    chatService.setSelectDialog(diaglog);

        //    this.props.finishLoadingAction();
        //    this.props.history.push("/message-chat/" + _id);
        //}
        //catch {
        //    alert("خطا در برقراری ارتباط ، مجددا سعی نمایید")
        //}

        //this.props.finishLoadingAction();

    }

    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="doctors-page">

                    <LoadingPage />


                    { /* <UserSummary /> */}

                    <div className="row p-0 m-0">

                        {this.state.meetings.length === 0
                            ?
                            (
                                <>
                                    <div className="col-12 mx-auto">
                                        <br />
                                        <center > در حال حاضر جلسه‌ای در آرشیو وجود ندارد </center>
                                        <br />
                                    </div>
                                </>
                            )
                            :
                            (
                                <>
                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                    {
                                        this.state.meetings.map(item => (
                                            <div className="col-xl-4 col-sm-6 px-2" key={item.id}>
                                                <div className="archive-widget">
                                                    <div className="row p-0 m-0">
                                                        <div className="col-5 user-side">
                                                            <div>
                                                                <img src={baseUrlAddress.getFilesBaseUrl() + item.supply_Avatar} />
                                                                <h4>{item.supply_UserName}</h4>
                                                                <p>
                                                                    مشاور
                                            </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-2 user-connection">
                                                            {(item.meetingType === 0) &&
                                                                <i className="fas fa-comments"></i>
                                                            }
                                                            {(item.meetingType === 1) &&
                                                                <i className="fas fa-phone-volume"></i>
                                                            }
                                                            {(item.meetingType === 2) &&
                                                                <i className="fas fa-video"></i>
                                                            }

                                                        </div>
                                                        <div className="col-5 user-side">
                                                            <div>
                                                                <img src={baseUrlAddress.getFilesBaseUrl() + item.demand_Avatar} />
                                                                <h4>{item.demand_UserName}</h4>
                                                                <p>
                                                                    مراجع
                                            </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 archive-detail">
                                                            <div className="row p-0 m-0">
                                                                <div className="col-12 px-2 detail-it-in-archive">
                                                                    <p>
                                                                        کد جلسه
                                                                        :
                                                      {item.id}
                                                                    </p>
                                                                </div>
                                                                <div className="col-12 px-2 detail-it-in-archive">
                                                                    <p>
                                                                        تاریخ جلسه
                                                                        :
                                                      {item.proposaled_Start_DateTime_String_Shamsi}
                                                                    </p>
                                                                </div>
                                                                <div className="col-12 px-2 detail-it-in-archive">
                                                                    <p>
                                                                        نرم افزار
                                                                        :
                                                                        {item.typeMeet}
                                                                    </p>
                                                                </div>
                                                                
                                                                <div className="col-12 px-2 detail-it-in-archive">
                                                                    <p>
                                                                        موضوع
                                                                        :
                                                      {item.consultantTypeName}
                                                                    </p>
                                                                </div>
                                                                <div className="col-6 p-2">
                                                                    <div className="archive-detail-widget">
                                                                        <p>
                                                                            <span>
                                                                                {item.timeDuration_InMinute}
                                                                                &nbsp;
                                                                                دقیقه
                                                                            </span>
                                                                            مدت زمان
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 p-2">
                                                                    <div className="archive-detail-widget">
                                                                        <p>
                                                                            <span>
                                                                                {(item.timeDuration_InMinute === 0)
                                                                                    ?
                                                                                    (
                                                                                        <span>
                                                                                            0
                                                                                            تومان
                                                                                        </span>
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        <span>
                                                                                            {(item.costDuration_InDollar === 0)
                                                                                                ?
                                                                                                (
                                                                                                    <span>در حال پردازش</span>
                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    <span>
                                                                                                        {item.costDuration_InDollar}
                                                                                                        &nbsp;
                                                                                                        تومان
                                                                                                    </span>
                                                                                                )
                                                                                            }
                                                                                        </span>
                                                                                    )
                                                                                }
                                                                            </span>
                                                                            حق مشاوره
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <Link to={"/file-record/" + item.demand_UserId} className="btn btn-start-consult rounded mt-2">
                                                        مشاهده پرونده کاربر
                                    </Link>
                                                    {(item.meetingType === 0) &&
                                                        <a className="btn btn-start-consult rounded mt-2" onClick={() => this.handleGotoChat(item.chat_MeetingId)}>
                                                            جزئیات چت
                                        </a>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                </>
                            )
                        }

                    </div>
                </div>


            </React.Fragment >
        );
    }
}


// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(MeetingsArchive));

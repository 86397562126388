import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import convertUrltoEncode from "../../helper/validation/convertUrltoEncode";



import * as meetingResavationService from "../../services/meeting/meetingResavationService";
import * as consultantService from "../../services/consultant/consultantService";
import * as meetingService from "../../services/meeting/meetingService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

//import CallService from '../../services/cc-video/call-service';
//import ChatService from "../../services/cc-message/chat-service";
import * as onlineService from "../../services/online/onlineService";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";

class MeetingsReserveArchive extends Component {
    constructor(props) {
        super(props);
        //props.xyz
        //props.selectedUsersIds
        //props.closeSelect
        //props.initRemoteStreams
        //props.setLocalStream
        //props.isActiveSelect
        //props.isActiveCall
        //props.resetState
        //props.unselectUser
        //props.selectUser
        //props.currentCall_Demand_ChatOpcId
        //props.currentCall_Supply_ChatOpcId
        //props.currentCall_Demand_UserId
        //props.currentCall_Supply_UserId
        //props.currentCall_Meeting_Id
        //props.changeMeetingParameters

        //console.log("reserve archive : " + this.props.selectedUsersIds.length)
        //console.log("reserve archive : " + this.props.isActiveCall)

        this.state = {
            meetings: [],
            reserves: [],
            users: [],
            pageSize: 100,
            currentPage: 1,
            intervalId: null,
            readyToCall: false,
            isOnline: false
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        store.dispatch({
            type: menuActionTypes.MeetingsReserveArchive
        });

        this.props.startLoadingAction();


        const _check = await onlineService.checkOnline();
        if (_check.success === true)
            this.setState({ isOnline: _check.data.isOnline });


        const _List = await meetingResavationService.getList();
        if (_List.success === true)
            if (_List.data !== null)
                this.setState({ reserves: _List.data });

        const _mList = await meetingService.getOnlineList();
        if (_mList.success === true)
            if (_mList.data !== null)
                this.setState({ meetings: _mList.data });

        const _uList = await meetingService.getMyDemandList();
        if (_uList.success === true)
            if (_uList.data !== null)
                this.setState({ users: _uList.data });

        this.props.finishLoadingAction();


        var intervalId = setInterval(this.intervalTimer, 10000);
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervalId: intervalId });

        window._that = this;
        setTimeout(
            function () {
                window._that.setState({ readyToCall: true });
            },
            4000
        );

    }

    async componentWillUnmount() {
        // use intervalId from the state to clear the interval
        clearInterval(this.state.intervalId);
    }


    intervalTimer = () => {


        (async () => {
            const _check = await onlineService.checkOnline();
            if (_check.success === true) {
                this.setState({ isOnline: _check.data.isOnline });
            }
        })();


        (async () => {
            const _List = await meetingResavationService.getList();
            if (_List.success === true)
                if (_List.data !== null) {
                    this.setState({ reserves: _List.data });
                }
        })();


        (async () => {
            const _mList = await meetingService.getOnlineList();
            if (_mList.success === true)
                if (_mList.data !== null)
                    this.setState({ meetings: _mList.data });
        })();

        //window.location.reload();
    }

    creatNewMeetingHandle = async (
        demandUserId,
        supplyUserId,
        demandOccupantId,
        supplyOccupantId,
        meetingId,
        meetingType,
        chatId,
        anOtherCallAvatar,
        anOtherCallName) => {


        this.props.startLoadingAction();


        //props.currentCall_Demand_ChatOpcId
        //props.currentCall_Supply_ChatOpcId
        //props.currentCall_Demand_UserId
        //props.currentCall_Supply_UserId
        //props.currentCall_Meeting_Id
        //props.changeMeetingParameters

        this.props
            .changeMeetingParameters(
                demandUserId,
                anOtherCallName,
                anOtherCallAvatar,
                supplyUserId,
                this.props.accountState.currentUser.FirstName + " " + this.props.accountState.currentUser.LastName,
                this.props.accountState.currentUser.Avatar,
                demandOccupantId,
                supplyOccupantId,
                meetingId,
                meetingType,
                chatId);


        if (meetingType === 0) {

            try {
                //const ___diaglog = await ChatService.fetchDialogs_Really_ById_V2(chatId);
                //ChatService.setSelectDialog(___diaglog);

                // use intervalId from the state to clear the interval
                clearInterval(this.state.intervalId);

                this.props.finishLoadingAction();
                //this.props.history.push("/message-chat/" + ___diaglog.id); // ___diaglog.id == chatId
            }
            catch {
                alert("خطا در برقراری ارتباط ، مجددا سعی نمایید");
                window.location.reload();
            }

        }
        if (meetingType === 1) {

            try {
                // use intervalId from the state to clear the interval
                clearInterval(this.state.intervalId);

                this.props.finishLoadingAction();
                //this.startVoiceCall(demandOccupantId);
            }
            catch {
                alert("خطا در برقراری ارتباط ، مجددا سعی نمایید");
                window.location.reload();
            }

        }
        if (meetingType === 2) {

            try {
                // use intervalId from the state to clear the interval
                clearInterval(this.state.intervalId);

                this.props.finishLoadingAction();
                //this.startCall(demandOccupantId);
            }
            catch {
                alert("خطا در برقراری ارتباط ، مجددا سعی نمایید");
                window.location.reload();
            }

        }

    }




    startCall = (chat_Demand_OccupantId) => {
        //let {
        //    selectedUsersIds,
        //    closeSelect,
        //    initRemoteStreams,
        //    setLocalStream,
        //    unselectUser,
        //    selectUser
        //} = this.props;

        //selectUser(chat_Demand_OccupantId);
        //selectedUsersIds.push(chat_Demand_OccupantId);

        ////console.log("----------------------**********************************************************-----------------");
        ////console.log("chat_Supply_OccupantId : " + chat_Demand_OccupantId);
        ////console.log("selectedUsersIds : " + selectedUsersIds);


        //if (selectedUsersIds.length === 0) {
        //    CallService.showToast('Select at less one user to start Videocall');
        //}
        //else {
        //    closeSelect();
        //    initRemoteStreams(selectedUsersIds);
        //    CallService.startCall(selectedUsersIds).then(setLocalStream);
        //}
    };

    startVoiceCall = (chat_Demand_OccupantId) => {
        //let {
        //    selectedUsersIds,
        //    closeSelect,
        //    initRemoteStreams,
        //    setLocalStream,
        //    unselectUser,
        //    selectUser
        //} = this.props;

        //selectUser(chat_Demand_OccupantId);
        //selectedUsersIds.push(chat_Demand_OccupantId);

        //if (selectedUsersIds.length === 0) {
        //    CallService.showToast('Select at less one user to start VoiceCall');
        //}
        //else {
        //    closeSelect();
        //    initRemoteStreams(selectedUsersIds);
        //    CallService.startCall(selectedUsersIds, "AUDIO").then(setLocalStream);
        //}
    };

    stopCall = () => {
        //const { resetState } = this.props;

        //CallService.stopCall();
        //resetState();
    };



    //_____OLD_____creatNewMeetingHandle = async (demandUserId, meetingType, reservedId) => {
    //    //e.preventDefault();

    //    // قبلش چک بشه که نفر مقابل زودتر این رزرو رو تبدیل به جلسه نکرده باشه
    //    // و اینکه بعد از انجام دیگه از لیست رزرو حذف بشه چون در لیست آرشیو جلسات است

    //    const reqData = {
    //        DemandId: demandUserId + "",
    //        MeetingTypeId: meetingType + "",
    //        RelatedTo_MeetingResaved_Id: reservedId + ""
    //    };
    //    const _res = await meetingService.creatNewMeeting(reqData);
    //    if (_res.success === true) {

    //        // type: 2 === PublicDialog === Private Group
    //        const params = {
    //            type: 2,
    //            occupants_ids: [_res.data.chat_Demand_OccupantId, _res.data.chat_Supply_OccupantId],
    //            name: "d: " + _res.data.demand_UserId + " - s: " + _res.data.supply_UserId
    //        };

    //        window._kbh_chat_Supply_OccupantId = _res.data.chat_Supply_OccupantId;
    //        window._kbh_chat_Demand_OccupantId = _res.data.chat_Demand_OccupantId;
    //        window._kbh_meeting_supply_UserId = _res.data.supply_UserId;
    //        window._kbh_meeting_demand_UserId = _res.data.demand_UserId;
    //        window._kbh_meeting_id = _res.data.id;

    //        // type: 2 === PublicDialog === Private Group
    //        ChatService.createPublicDialog_V2(params.occupants_ids, params.name, null)
    //            .then((newDialog) => {

    //                ChatService.sendChatAlertOnCreate_V2(newDialog);

    //                const ccData = {
    //                    Chat_MeetingId: newDialog.id + "",
    //                    Id: window._kbh_meeting_id,
    //                    Supply_UserId: window._kbh_meeting_supply_UserId,
    //                    Demand_UserId: window._kbh_meeting_demand_UserId,
    //                    RelatedTo_MeetingResaved_Id: reservedId + ""
    //                };

    //                (async () => {
    //                    const ___res = await meetingService.updateMeetingWithCCId(ccData);
    //                    if (___res.success === true) {
    //                        //this.props.history.push("/meetings-archive");

    //                        if (meetingType === 0) {
    //                            //const ___diaglog = await ChatService.fetchDialogs_Really_ById_V2(ccData.Chat_MeetingId);
    //                            //ChatService.setSelectDialog(___diaglog);
    //                            ChatService.setSelectDialog(newDialog);
    //                            this.props.history.push("/message-chat/" + newDialog.id);
    //                        }
    //                        if (meetingType === 1) {
    //                            this.startVoiceCall(window._kbh_chat_Demand_OccupantId);
    //                        }
    //                        if (meetingType === 2) {
    //                            this.startCall(window._kbh_chat_Demand_OccupantId);
    //                        }

    //                    }
    //                    else {
    //                        toast.error("Error: " + ___res.message);
    //                    }
    //                })();

    //            })
    //            .catch((error) => {
    //                toast.error("Error: " + error);
    //            });
    //    }
    //    else {
    //        this.props.history.push("/meetings-archive");
    //    }
    //}



    //handleGotoChat = async (_id) => {
    //    //const diaglog = await ChatService.fetchDialogsById(_id);
    //    try {
    //        const diaglog = await ChatService.fetchDialogs_Really_ById_V2(_id);
    //        ChatService.setSelectDialog(diaglog);
    //        this.props.history.push("/message-chat/" + _id);
    //    }
    //    catch {
    //        alert("خطا در برقراری ارتباط ، مجددا سعی نمایید")
    //    }
    //}

    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند


    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="doctors-page">

                    <LoadingPage />


                    <div className="row p-0 m-0 pb-5">


                        <div className="live-conv px-0 w-100 about-wrapper rounded-0 pt-2 pb-3 mt-0">
                            <p className="text-center">
                                <span style={{ fontWeight: "600", color: "blue", direction: "rtl" }}>
                                    جلسه آنلاین در حال برگزاری :
                                </span>
                                <br />
                                {/*<span style={{ direction: "rtl" }}>*/}
                                {/*    برای برگزاری جلسه زمانی که موبایل شما iphone است حتما از مرورگر safari استفاده کنید*/}
                                {/*</span>*/}
                            </p>

                            {this.state.meetings.length === 0
                                ?
                                (
                                    <>
                                        <div className="col-12 mx-auto">
                                            <center> در حال حاضر جلسه‌ی آنلاینی وجود ندارد </center>
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>

                                        { /* ----------- key={item._id} must be for list in react ---------------- */}
                                        {this.state.meetings.map(item => (

                                            <>
                                                <div className="col-xl-5 col-md-6 col-sm-10 mx-auto" key={item.id}>
                                                    <div className="archive-widget">
                                                        <div className="row p-0 m-0">
                                                            <div className="col-5 user-side">
                                                                <div>
                                                                    <img src={baseUrlAddress.getFilesBaseUrl() + item.supply_Avatar} />
                                                                    <h4>{item.supply_UserName}</h4>
                                                                    <p>
                                                                        مشاور
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 user-connection">
                                                                {(item.meetingType === 0) &&
                                                                    <i className="fas fa-comments"></i>
                                                                }
                                                                {(item.meetingType === 1) &&
                                                                    <i className="fas fa-phone-volume"></i>
                                                                }
                                                                {(item.meetingType === 2) &&
                                                                    <i className="fas fa-video"></i>
                                                                }
                                                            </div>
                                                            <div className="col-5 user-side">
                                                                <div>
                                                                    <img src={baseUrlAddress.getFilesBaseUrl() + item.demand_Avatar} />
                                                                    <h4>{item.demand_UserName}</h4>
                                                                    <p>
                                                                        مراجع
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 archive-detail">
                                                                <div className="row p-0 m-0">
                                                                    <div className="col-6 p-2">
                                                                        <div className="archive-detail-widget">
                                                                            <p>
                                                                                <span>
                                                                                    {item.timeDuration_InMinute}
                                                                                    دقیقه
                                                                                </span>
                                                                                مدت زمان
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6 p-2">
                                                                        <div className="archive-detail-widget">
                                                                            <p>
                                                                                <span>
                                                                                    در حال پردازش
                                                                                </span>
                                                                                حق مشاوره
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="w-100">
                                                            <div className="row p-0 m-0">
                                                                <div className="col-12 px-1">
                                                                    <div className="row p-0 mx-0 mt-3">
                                                                        <div className="col-12 px-1">
                                                                            <p className="text-center">

                                                                                کد جلسه
                                                                                :
                                                                                {item.id}
                                                                            </p>
                                                                            <p className="text-center">

                                                                                موضوع
                                                                                :
                                                                                {item.consultantTypeName}
                                                                            </p>
                                                                            <p className="text-center">

                                                                                نرم افزار
                                                                                :
                                                                                {item.typeMeet}
                                                                            </p>


                                                                            <div>


                                                                                {(item.urlMeet !== null && item.urlMeet !== "") &&
                                                                                    <p>
                                                                                        <a
                                                                                            target="_blank"
                                                                                            href={item.urlMeet}
                                                                                            className="btn btn-start-consult rounded"
                                                                                        >
                                                                                            ورود به اتاق جلسه مشاوره
                                                                                        </a>
                                                                                    </p>
                                                                                }


                                                                                {this.state.readyToCall
                                                                                    ?
                                                                                    <>


                                                                                        { /*

                                                                                        {(item.meetingType === 0) &&
                                                                                            <a
                                                                                                className="btn btn-start-consult rounded"
                                                                                                onClick={() => this.creatNewMeetingHandle(item.demand_UserId, item.supply_UserId, item.chat_Demand_OccupantId, item.chat_Supply_OccupantId, item.id, item.meetingType, item.chat_MeetingId, item.demand_Avatar, item.demand_UserName)}
                                                                                            >
                                                                                                شروع چت
                                                                                            </a>
                                                                                        }
                                                                                        {(item.meetingType === 1) &&
                                                                                            <a
                                                                                                target=""
                                                                                                href={baseUrlAddress.getCallsBaseUrl() + "/?username=" + item.supply_Real_UserName + "&usernameside=" + item.demand_Real_UserName + "&mid=" + item.id + "&mtype=" + item.meetingType}
                                                                                                className="btn btn-start-consult rounded"
                                                                                            >
                                                                                                ورود به اتاق جلسه مشاوره
                                                                                            </a>
                                                                                        }
                                                                                        {(item.meetingType === 2) &&
                                                                                            <a
                                                                                                href={baseUrlAddress.getCallsBaseUrl() + "/?username=" + item.supply_Real_UserName + "&usernameside=" + item.demand_Real_UserName + "&mid=" + item.id + "&mtype=" + item.meetingType}
                                                                                                className="btn btn-start-consult rounded"
                                                                                            >
                                                                                                ورود به اتاق جلسه مشاوره
                                                                                            </a>
                                                                                        }

                                                                                         */}

                                                                                        { /* ----------- OLD ---------------- */}
                                                                                        { /*
                                                                                        {(item.meetingType === 1) &&
                                                                                            <a
                                                                                                onClick={() => this.creatNewMeetingHandle(item.demand_UserId, item.supply_UserId, item.chat_Demand_OccupantId, item.chat_Supply_OccupantId, item.id, item.meetingType, item.chat_MeetingId, item.demand_Avatar, item.demand_UserName)}
                                                                                                className="btn btn-start-consult rounded"
                                                                                            >
                                                                                                شروع تماس صوتی
                                                                                </a>
                                                                                        }
                                                                                        {(item.meetingType === 2) &&
                                                                                            <a
                                                                                                onClick={() => this.creatNewMeetingHandle(item.demand_UserId, item.supply_UserId, item.chat_Demand_OccupantId, item.chat_Supply_OccupantId, item.id, item.meetingType, item.chat_MeetingId, item.demand_Avatar, item.demand_UserName)}
                                                                                                className="btn btn-start-consult rounded"
                                                                                            >
                                                                                                شروع تماس تصویری
                                                                                </a>
                                                                                        }
                                                                                        */}
                                                                                        { /* ----------- OLD ---------------- */}

                                                                                        {this.state.isOnline
                                                                                            ?
                                                                                            <div style={{ textAlign: "center", paddingTop: "10px", color: "green" }}>
                                                                                                شما آنلاین هستید
                                                                                            </div>
                                                                                            :
                                                                                            <div style={{ textAlign: "center", paddingTop: "10px", color: "red" }}>
                                                                                                شما آفلاین هستید
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <a
                                                                                            className="btn btn-stock rounded"
                                                                                            style={{ border: "1px solid darkred" }}
                                                                                        >
                                                                                            در حال آماده سازی تماس ...
                                                                                        </a>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </>

                                        ))}
                                        { /* ----------- key={item._id} must be for list in react ---------------- */}

                                    </>
                                )
                            }

                        </div>




                        <div className="live-conv px-0 w-100 about-wrapper rounded-0 pt-2 pb-3 mt-3">
                            <p className="text-center" style={{ fontWeight: "600", color: "blue", direction: "rtl" }}>
                                لیست جلسات رزرو شده :
                            </p>



                            {this.state.reserves.length === 0
                                ?
                                (
                                    <>
                                        <div className="col-12 mx-auto">
                                            <center> در حال حاضر درخواست رزرو ندارید </center>
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>

                                        <div className="row mr-3 ml-3">

                                            { /* ----------- key={item._id} must be for list in react ---------------- */}
                                            {this.state.reserves.map(item => (

                                                <div className="col-xl-4 col-sm-6 px-2" key={item.id}>
                                                    <div className="notif-widget meetings-reserve-archive-card">
                                                        {/*<div className="reserve-type-icon-big">*/}
                                                        {/*    {(item.meetingType === 0) &&*/}
                                                        {/*        <i className="fas fa-comments"></i>*/}
                                                        {/*    }*/}
                                                        {/*    {(item.meetingType === 1) &&*/}
                                                        {/*        <i className="fas fa-phone-volume"></i>*/}
                                                        {/*    }*/}
                                                        {/*    {(item.meetingType === 2) &&*/}
                                                        {/*        <i className="fas fa-video"></i>*/}
                                                        {/*    }*/}
                                                        {/*</div>*/}
                                                        <div className="row p-0 m-0">
                                                            <div className="col-12 p-0">
                                                                <div className="row p-0 m-0">
                                                                    <div className="col-3 p-0 d-flex align-items-center">
                                                                        <div className="user-image" style={{ backgroundImage: "url(" + baseUrlAddress.getFilesBaseUrl() + convertUrltoEncode(item.demand_Avatar) + ")" }}>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-9 p-0 d-flex align-items-center">
                                                                        <div className="w-100 notif-name">
                                                                            <h2>{item.demand_UserName}</h2>
                                                                            <p>
                                                                                مراجع
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 p-0 mt-1">
                                                                <div className="reserve-offer">
                                                                    <div className="reserve-offer-body">
                                                                        <ul className="px-1">
                                                                            {/*<li>*/}
                                                                            {/*    نوع جلسه :*/}
                                                                            {/*    <span>*/}
                                                                            {/*        {(item.meetingType === 0) &&*/}
                                                                            {/*            <span>*/}
                                                                            {/*                &nbsp;*/}
                                                                            {/*                چت*/}
                                                                            {/*                &nbsp;*/}
                                                                            {/*            </span>*/}
                                                                            {/*        }*/}
                                                                            {/*        {(item.meetingType === 1) &&*/}
                                                                            {/*            <span>*/}
                                                                            {/*                &nbsp;*/}
                                                                            {/*                تماس صوتی*/}
                                                                            {/*                &nbsp;*/}
                                                                            {/*            </span>*/}
                                                                            {/*        }*/}
                                                                            {/*        {(item.meetingType === 2) &&*/}
                                                                            {/*            <span>*/}
                                                                            {/*                &nbsp;*/}
                                                                            {/*                تماس  تصویری*/}
                                                                            {/*                &nbsp;*/}
                                                                            {/*            </span>*/}
                                                                            {/*        }*/}

                                                                            {/*        {*/}
                                                                            {/*            item.isOnlineRequest &&*/}
                                                                            {/*            (<span style={{ color: "#379199" }} >*/}
                                                                            {/*                (آنلاین)*/}
                                                                            {/*            </span>)*/}
                                                                            {/*        }*/}
                                                                            {/*    </span>*/}
                                                                            {/*</li>*/}
                                                                            <li>
                                                                                موضوع :
                                                                                <span>
                                                                                    &nbsp;
                                                                                    {item.consultantTypeName}
                                                                                </span>
                                                                            </li>
                                                                            <li>
                                                                                روز پیشنهادی :
                                                                                <span>
                                                                                    &nbsp;
                                                                                    {item.start_Proposal_FullDate_InLocal_Shamsi}
                                                                                </span>
                                                                            </li>
                                                                            <li>
                                                                                زمان پیشنهادی :
                                                                                <span>
                                                                                    &nbsp;
                                                                                    {" " + item.start_Proposal_FromHour_InLocal + " "}
                                                                                    &nbsp;
                                                                                    الی
                                                                                    &nbsp;
                                                                                    {" " + item.start_Proposal_ToHour_InLocal + " "}
                                                                                </span>
                                                                            </li>
                                                                            <li>
                                                                                وضعیت :
                                                                                <span>
                                                                                    &nbsp;
                                                                                    {(item.status === 0) &&
                                                                                        <span>
                                                                                            در انتظار تایید مشاور
                                                                                        </span>
                                                                                    }
                                                                                    {(item.status === 1) &&
                                                                                        <span>
                                                                                            پذیرفته شده توسط مشاور
                                                                                        </span>
                                                                                    }
                                                                                    {(item.status === 2) &&
                                                                                        <span>
                                                                                            رد شده توسط مشاور - در جهت حفظ رضایت‌مندی مراجع از شما، خواهشمندیم جلسات رد شده توسط خودتان را به حداقل رسانید
                                                                                        </span>
                                                                                    }
                                                                                    {(item.status === 3) &&
                                                                                        <span>
                                                                                            در حال بررسی توسط کاربر
                                                                                        </span>
                                                                                    }
                                                                                    {(item.status === 4) &&
                                                                                        <span>
                                                                                            پذیرفته شده توسط کاربر
                                                                                        </span>
                                                                                    }
                                                                                    {(item.status === 5) &&
                                                                                        <span>
                                                                                            حذف شده توسط کاربر
                                                                                        </span>
                                                                                    }
                                                                                    &nbsp;
                                                                                </span>
                                                                            </li>

                                                                            <li>
                                                                                کد رزرو جلسه :
                                                                                <span>
                                                                                    &nbsp;
                                                                                    {item.id}
                                                                                    &nbsp;
                                                                                </span>
                                                                            </li>
                                                                            <li>
                                                                                نرم افزار :
                                                                                <span>
                                                                                    &nbsp;
                                                                                    {item.typeMeet}
                                                                                    &nbsp;
                                                                                </span>
                                                                            </li>

                                                                        </ul>
                                                                        <div className="w-100">
                                                                            <div className="row p-0 m-0">
                                                                                <div className="col-12 px-1">
                                                                                    {(item.isFinalAccepted_Status === true) &&
                                                                                        <>
                                                                                            {(item.status === 1) &&
                                                                                                <div className="row p-0 mx-0 mt-3">
                                                                                                    <div className="col-12 px-1 labels-status">
                                                                                                        <Link
                                                                                                            style={{ width: "100%" }}
                                                                                                            to={"/meetings-reserve-detail/" + item.id + ""}
                                                                                                            className="is-active"
                                                                                                        >
                                                                                                            نمایش جزییات (پذیرفته شده)
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                            {(item.status === 2) &&
                                                                                                <div className="row p-0 mx-0 mt-3">
                                                                                                    <div className="col-12 px-1 labels-status">
                                                                                                        <Link
                                                                                                            style={{ width: "100%" }}
                                                                                                            to={"/meetings-reserve-detail/" + item.id + ""}
                                                                                                            className="not-active"
                                                                                                        >
                                                                                                            نمایش جزییات (رد شده)
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                            {(item.status === 5) &&
                                                                                                <div className="row p-0 mx-0 mt-3">
                                                                                                    <div className="col-12 px-1 labels-status">
                                                                                                        <Link
                                                                                                            style={{ width: "100%" }}
                                                                                                            to={"/meetings-reserve-detail/" + item.id + ""}
                                                                                                            className="not-active"
                                                                                                        >
                                                                                                            نمایش جزییات (حذف شده)
                                                                                                        </Link>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                    {(item.isFinalAccepted_Status === false) &&
                                                                                        <div className="row p-0 mx-0 mt-3">
                                                                                            <div className="col-12 px-1 labels-status">
                                                                                                <Link
                                                                                                    style={{ width: "100%" }}
                                                                                                    to={"/meetings-reserve-detail/" + item.id + ""}
                                                                                                    className="is-waiting"
                                                                                                >
                                                                                                    نمایش جزییات (در انتظار تایید)
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    }

                                                                                    <Link to={"/file-record/" + item.demand_UserId} className="btn btn-acc rounded mt-2">
                                                                                        مشاهده پرونده کاربر
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            ))}
                                            { /* ----------- key={item._id} must be for list in react ---------------- */}

                                        </div>

                                    </>
                                )
                            }


                        </div>







                        <div className="live-conv px-0 w-100 about-wrapper rounded-0 pt-2 pb-3 mt-3">
                            <p className="text-center" style={{ fontWeight: "600", color: "blue", direction: "rtl" }}>
                                لیست کاربران با جلسات آرشیو شده :
                            </p>



                            {this.state.users.length === 0
                                ?
                                (
                                    <>
                                        <div className="col-12 mx-auto">
                                            <center> در حال حاضر جلسه ای در آرشیو وجود ندارد </center>
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div className="row mr-3 ml-3">
                                            { /* ----------- key={item._id} must be for list in react ---------------- */}
                                            {
                                                this.state.users.map(item => (
                                                    <div className="col-xl-3 col-sm-6 px-2" key={item.userId}>
                                                        <div className="archive-widget">
                                                            <div className="row p-0 m-0">
                                                                <div className="col-6 user-side">
                                                                    <div>
                                                                        <img src={baseUrlAddress.getFilesBaseUrl() + item.avatar} />
                                                                        <h4>{item.firstLastName}</h4>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 user-side">
                                                                    <div>
                                                                        <p>
                                                                            تعداد : {item.meetCount} جلسه
                                                                        </p>
                                                                        <p>
                                                                            زمان : {item.meetTimeSum} دقیقه
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <Link to={"/file-record/" + item.userId} className="btn btn-acc rounded mt-2">
                                                                مشاهده جلسات و پرونده
                                                            </Link>

                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            { /* ----------- key={item._id} must be for list in react ---------------- */}
                                        </div>
                                    </>
                                )
                            }


                        </div>









                    </div>
                </div>


            </React.Fragment>
        );
    }
}


const map_State_to_Props = state => ({
    accountState: state.accountReducer,
    loadingState: state.loadingReducer
});

const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(MeetingsReserveArchive));

import * as axiosRequest from "../../helper/axiosRequest/httpRequest";
import * as meetingUrlAddress from "../../utils/urlAddress/meetingUrlAddress";

export async function getList(data) {
    return await axiosRequest.HttpPost(meetingUrlAddress.meetingList, data);
}

export async function getOnlineList() {
    return await axiosRequest.HttpPost(meetingUrlAddress.meetingOnlineList, null);
}


export async function getMyDemandList() {
    return await axiosRequest.HttpPost(meetingUrlAddress.getMyDemandList, null);
}


export async function creatNewMeeting(meetData) {
    return await axiosRequest.HttpPost(meetingUrlAddress.meetingCreatNew, meetData);
}

export async function updateMeetingWithCCId(meetccData) {
    return await axiosRequest.HttpPost(meetingUrlAddress.meetingUpdateCCId, meetccData);
}

export async function updateCurrentMeeting(meetData) {
    return await axiosRequest.HttpPost(meetingUrlAddress.meetingUpdateCurrent, meetData);
}


export async function checkIsMeeting(meetccData) {
    return await axiosRequest.HttpPost(meetingUrlAddress.checkIsMeeting, meetccData);
}

export async function checkAllIsMeeting() {
    return await axiosRequest.HttpPost(meetingUrlAddress.checkAllIsMeeting, null);
}
import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

//import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
//import { toast } from "react-toastify";
//import chatService from "../../services/cc-message/chat-service";
//import ChatService from "../../services/cc-message/chat-service";


import store from '../../redux/store/store';

import * as alertActionTypes from "../../redux/actionTypes/alertActionTypes";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'; // npm uninstall node-sass >>> npm install node-sass@4.14.1 -save
import withReactContent from 'sweetalert2-react-content';

//import loadingPic from "../../theme/assets/images/loading-gif.gif";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

class alertPage extends Component {
    //constructor(props) {
    //    super(props);
    //}



    showMesageBox = (sender_id, body, dialog_id) => {


        store.dispatch({
            type: alertActionTypes.MessageCleared,
            payload: {
                sender_id: null,
                body: null,
                dialog_id: null,
            }
        });


        //alert("6");
        //alert(sender_id);
        //alert(body);
        //alert(dialog_id);
        //console.error("------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******------*******")

        //window.param__sender_id = sender_id;
        //window.param__body = body;
        //window.param__dialog_id = dialog_id;

        //const MySwal = withReactContent(Swal)
        //MySwal.fire({
        //    title: 'یک پیام جدید در جلسه چت دارید، با ورود به جلسه می توانید پیام را پاسخ دهید',
        //    text: "متن پیام جدید : ' " + window.param__body + " ' ",
        //    icon: 'success',
        //    showCancelButton: false,
        //    iconColor: '#ffce46',
        //    confirmButtonColor: '#489fa4',
        //    cancelButtonColor: '#727272',
        //    confirmButtonText: 'ورود به جلسه',
        //    cancelButtonText: 'فعلا نه',
        //    allowOutsideClick: false,
        //    allowEscapeKey: false,
        //})
        //    .then((result) => {
        //        if (result.isConfirmed) {

        //            //alert("7");


        //            (async () => {

        //                try {

        //                    //alert("8");
        //                    //this.props.startLoadingAction();

        //                    store.dispatch({
        //                        type: alertActionTypes.MessageCleared,
        //                        payload: {
        //                            sender_id: null,
        //                            body: null,
        //                            dialog_id: null,
        //                        }
        //                    });


        //                    const ___diaglog = await ChatService.fetchDialogs_Really_ById_V2(window.param__dialog_id);
        //                    ChatService.setSelectDialog(___diaglog);
        //                    //this.props.finishLoadingAction();
        //                    this.props.history.push("/message-chat/" + ___diaglog.id); // ___diaglog.id == window.param__dialog_id

        //                }
        //                catch {
        //                    this.props.finishLoadingAction();
        //                    alert("خطا در برقراری ارتباط ، صفحه مرورگر را رفرش کرده و مجددا سعی نمایید");
        //                }

        //            })();

        //        }
        //    })

    };



    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        //if (store.getState().alertReducer.showAlert) {
        //    this.showMesageBox(
        //        store.getState().alertReducer.message.sender_id,
        //        store.getState().alertReducer.message.body,
        //        store.getState().alertReducer.message.dialog_id
        //    );
        //}

        return (
            <React.Fragment>

                {(this.props.alertState.showAlert === true) &&
                    this.showMesageBox(
                        this.props.alertState.message.sender_id,
                        this.props.alertState.message.body,
                        this.props.alertState.message.dialog_id
                    )
                }

            </React.Fragment>
        );

    }
}

// this.props.loadingState.isLoading
// this.props.alertState.showAlert
// this.props.alertState.message.sender_id
// this.props.alertState.message.body
// this.props.alertState.message.dialog_id
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer,
    alertState: state.alertReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(alertPage));

import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as blogService from "../../services/blog/blogService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";

class BlogList extends Component {
    constructor(props) {
        super(props);
        //props.handleFunc_myFunc

        // برای بار اولی که اپ بالا می آید
        this.state = {
            blogs: [],
            //blogs: blogService.getBlogs(),  // in 'componentDidMount' lifecycle hooks
            pageSize: 9,
            currentPage: 1
        };
    }

    // load ajax and get data from server
    async componentDidMount() {

        this.LoadNewList();

    }


    async componentDidUpdate(prevProps) {
        if (prevProps.category !== this.props.category) {
            this.LoadNewList();
        }
    }


    LoadNewList = async () => {

        this.props.startLoadingAction();


        console.log("blogList");
        console.log(this.props.category);

        let typeName = "";
        if (this.props.category === 1) {
            typeName = "image";
        }
        if (this.props.category === 2) {
            typeName = "audio";
        }
        if (this.props.category === 3) {
            typeName = "video";
        }

        let _reqData = {
            TypeName: typeName
        };

        const _blogList = await blogService.getBlogs(_reqData);
        if (_blogList !== null)
            this.setState({ blogs: _blogList });

        this.props.finishLoadingAction();

    }



    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        return (
            <React.Fragment>

                <LoadingPage />


                { /* ----------- key={item._id} must be for list in react ---------------- */}
                {this.state.blogs.map(item => (
                    <div className="col-md-4 px-1" key={item.id}>

                        <div className="card card-blog-home">
                            <span className="badge badge-content-type">

                                {(item.video != null) &&
                                    <i class="fas fa-video"></i>
                                }

                                {(item.image != "") &&
                                    <i class="fas fa-book"></i>
                                }

                                {(item.audio != null) &&
                                    <i class="fas fa-headphones-alt"></i>
                                }

                            </span>
                            <div className="blog-img-holder">
                                <Link to={"/blog-detail/" + item.id + ""} >
                                    <img src={baseUrlAddress.getFilesBaseUrl() + item.thumbnail} className="card-img" alt={item.title} />
                                </Link>
                            </div>
                            <div className="card-body">
                                <div className="post-actions">
                                    <div className="row p-0 m-0">
                                        <div className="col-6 p-0">
                                            <div className="action-icon row p-0 m-0">
                                                { /*
                                                <a className="bookmark">
                                                    <i className="fas fa-bookmark"></i>
                                                </a>
                                                */ }
                                            </div>
                                        </div>
                                        <div className="col-6 p-0">
                                            <div className="action-icon row p-0 m-0 d-flex justify-content-end">
                                                {item.count_Like > 0
                                                    ?
                                                    <a className="ml-2 like active">
                                                        {item.count_Like}
                                                        <i className="fas fa-heart mr-2"></i>
                                                    </a>
                                                    :
                                                    <a className="ml-2 like">
                                                        {item.count_Like}
                                                        <i className="fas fa-heart mr-2"></i>
                                                    </a>
                                                }
                                                {item.count_Comment > 0
                                                    ?
                                                    <a className="cm active">
                                                        {item.count_Comment}
                                                        <i className="fas fa-comment"></i>
                                                    </a>
                                                    :
                                                    <a className="cm">
                                                        {item.count_Comment}
                                                        <i className="fas fa-comment"></i>
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h5 className="card-title">
                                    <Link to={"/blog-detail/" + item.id + ""} style={{ color: "#575757" }}>
                                        {item.title}
                                    </Link>
                                </h5>
                                <div>
                                    <p>{item.createDateTimeString}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                ))}
                { /* ----------- key={item._id} must be for list in react ---------------- */}

            </React.Fragment>
        );
    }
}

// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(BlogList));

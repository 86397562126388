import * as baseUrlAddress from "./baseUrlAddress";

export const meetingList = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetings/GetList";
export const meetingOnlineList = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetings/GetOnlineList";
export const getMyDemandList = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetings/GetMyDemandList";
export const meetingCreatNew = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetings/CreatNew";
export const meetingUpdateCCId = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetings/UpdateCCId";
export const meetingUpdateCurrent = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetings/UpdateCurrent";
export const checkIsMeeting = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetings/CheckIsMeeting";
export const checkAllIsMeeting = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetings/CheckAllIsMeeting";



export const getCalendarList = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetings/GetCalendarList";



export const meetingResavationGetList = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetingResavations/GetList";
export const meetingResavationGetRejectedList = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetingResavations/GetRejectedList";
export const meetingResavationGetLostedList = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetingResavations/GetLostedList";
export const meetingResavationGetListByStartId = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetingResavations/GetListByStartId";
export const meetingResavationGetReserveInfo = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetingResavations/GetReserveInfo";
export const meetingResavationCreateNew = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetingResavations/CreateNew";
export const meetingResavationAccept = baseUrlAddress.getApiBaseUrl() + "/SupplyMeetingResavations/ResavationAccept";

import * as baseUrlAddress from "./baseUrlAddress";

export const loginUser = baseUrlAddress.getApiBaseUrl() + "/Account/Login";
export const logoutUser = baseUrlAddress.getApiBaseUrl() + "/Account/Logout";
//export const RegisterUser = baseUrlAddress.getApiBaseUrl() + "/Account/RegisterDemand";
export const refreshToken = baseUrlAddress.getApiBaseUrl() + "/Account/RefreshToken";
export const checkOTP = baseUrlAddress.getApiBaseUrl() + "/Account/CheckOTP";
export const forgotPassUser = baseUrlAddress.getApiBaseUrl() + "/Account/ForgotPassword";
export const changePassUser = baseUrlAddress.getApiBaseUrl() + "/Account/ChangePassword";
export const acceptRules = baseUrlAddress.getApiBaseUrl() + "/Account/AcceptRules";
export const changePassUserInForgot = baseUrlAddress.getApiBaseUrl() + "/Account/ChangePasswordInForgotPass";

export const ccEditDemand = baseUrlAddress.getApiBaseUrl() + "/DemandCCChat/EditChatUser";
export const ccEditSupply = baseUrlAddress.getApiBaseUrl() + "/SupplyCCChat/EditChatUser";

import * as axiosRequest from "../../helper/axiosRequest/httpRequest";
import * as commentUrlAddress from "../../utils/urlAddress/commentUrlAddress";

export async function setComment(reqData) {
    return await axiosRequest.HttpPost(commentUrlAddress.setComment, reqData);
}

export async function setLike(reqData) {
    return await axiosRequest.HttpPost(commentUrlAddress.setLike, reqData);
}

import React, { Component } from "react";
//import { Editor } from '@tinymce/tinymce-react';

import BlogList from "./blogList";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import * as blogService from "../../services/blog/blogService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

class BlogCreate extends Component {
    constructor(props) {
        super(props);

        // برای بار اولی که اپ بالا می آید
        this.state = {
            category: 0
        };
    }

    async componentDidMount() {
        store.dispatch({
            type: menuActionTypes.BlogCreate
        });

        //const _result = await blogService.getBlogTypesCount();
        //if (_result.success)
        //    this.setState({
        //        allCount: _result.data.all_Count,
        //        imageCount: _result.data.image_Count,
        //        videoCount: _result.data.video_Count,
        //        aduioCount: _result.data.aduio_Count,
        //    });

    }


    handleEditorChange = (content, editor) => {
        console.log('Content was updated:', content);
    }


    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="blog-single-page">
                    <div className="post-wrapper blog-single">
                        <div className="col-md-11 mx-auto py-3 p-0">
                            <form className="w-100">
                                <p className="desc">در این قسمت میتوانید محتوای مورد نظر خود را ایجاد کنید.</p>
                                <div className="form-group">

                                    <input type="text" className="form-control" placeholder="عنوان پست" />
                                </div>
                                <div className="form-group">

                                    <input type="text" className="form-control" placeholder="خلاصه پست" />
                                </div>
                                <div className="form-group">

                                    {/*
                                    <textarea className="form-control" placeholder="متن پست" rows="7"></textarea>
                                    */}

                                    {/*<Editor*/}
                                    {/*    apiKey='nz95tobwuw7ovhdftngqmsw9zl6yyhvts6d7240lu06f7odu'*/}
                                    {/*    initialValue="<p>This is the initial content of the editor</p>"*/}
                                    {/*    init={{*/}
                                    {/*        height: 500,*/}
                                    {/*        menubar: false,*/}
                                    {/*        plugins: [*/}
                                    {/*            "advlist autolink autosave link image lists charmap print preview hr anchor pagebreak spellchecker",*/}
                                    {/*            "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",*/}
                                    {/*            "table contextmenu directionality emoticons template textcolor paste fullpage textcolor colorpicker textpattern"*/}
                                    {/*        ],*/}
                                    {/*        toolbar: [*/}
                                    {/*            "newdocument undo redo | bold italic underline strikethrough | ltr rtl | alignleft aligncenter alignright alignjustify styleselect formatselect fontselect fontsizeselect | forecolor backcolor cut copy paste | bullist numlist | outdent indent blockquote | link unlink anchor image table | hr removeformat | subscript superscript | charmap emoticons | fullscreen  | code preview"*/}
                                    {/*        ],*/}
                                    {/*    }}*/}
                                    {/*    onEditorChange={this.handleEditorChange}*/}
                                    {/*/>*/}

                                </div>
                                <div className="form-group">
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="inputGroupFile" />
                                        <label className="custom-file-label" for="inputGroupFile" data-browse="جستجو فایل">آپلود فایل</label>
                                    </div>
                                </div>
                                { /*
                                <div className="form-group">
                                    <div className="row p-0 m-0">
                                        <div className="col-lg-6 px-0 pl-lg-1 mb-lg-0 mb-3">
                                            <select className="form-control w-100 px-2" name="status" id="status">
                                                <option>وضعیت پست</option>
                                                <option>فعال</option>
                                                <option>غیر فعال</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-6 px-0 pr-lg-1 mb-lg-0 mb-3">
                                            <select className="form-control w-100 px-2" name="ex2" id="ex2">
                                                <option>نمونه سلکت دو</option>
                                                <option>فعال</option>
                                                <option>غیر فعال</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                */}
                                <button type="button" className="btn btn-reserve-offline rounded">
                                    ایجاد پست
                                </button>

                            </form>
                        </div>
                    </div>
                </div>

              
            </React.Fragment>
        );
    }
}

export default BlogCreate;

import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";


import * as meetingResavationService from "../../services/meeting/meetingResavationService";
import * as meetingService from "../../services/meeting/meetingService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

//import ChatService from '../../services/cc-message/chat-service';
//import CallService from '../../services/cc-video/call-service';


import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";


import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";
import convertUrltoEncode from "../../helper/validation/convertUrltoEncode";




class MeetingsReserveDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reserves: [],
            pageSize: 100,
            currentPage: 1
        };
    }

    // load ajax and get data from server
    async componentDidMount() {
        let nid = this.props.match.params.nid; // nid is string
        if (nid === undefined || nid === "undefined" || nid === "0")
            this.props.history.push("/meetings-reserve-archive");

        const reqData = { StartId: nid + "" }; // Id is string
        const _List = await meetingResavationService.getListByStartId(reqData);
        if (_List.success === true)
            if (_List.data !== null)
                this.setState({ reserves: _List.data });
    }


    acceptReservationHandle = async (e, acceptType, reservedId, meetingTypeId, demandUserId) => {
        e.preventDefault();

        this.props.startLoadingAction();


        const reqData = {
            ReservedId: reservedId + "",
            AcceptType: acceptType + "",
        };

        const _res = await meetingResavationService.acceptReservation(reqData);
        if (_res.success === true) {
            //toast.success("Reservation Proccessed Successfully, Please Wait ..., Automaticly Redirect");

            if (acceptType !== "deny") {

                const reqData = {
                    DemandId: demandUserId + "",
                    MeetingTypeId: meetingTypeId + "",
                    RelatedTo_MeetingResaved_Id: reservedId + ""
                };
                const _res = await meetingService.creatNewMeeting(reqData);
                if (_res.success === true) {

                    // type: 2 === PublicDialog === Private Group
                    const params = {
                        type: 2,
                        occupants_ids: [_res.data.chat_Demand_OccupantId, _res.data.chat_Supply_OccupantId],
                        name: "d: " + _res.data.demand_UserId + " - s: " + _res.data.supply_UserId
                    };

                    window._kbh_chat_Supply_OccupantId = _res.data.chat_Supply_OccupantId;
                    window._kbh_chat_Demand_OccupantId = _res.data.chat_Demand_OccupantId;
                    window._kbh_meeting_supply_UserId = _res.data.supply_UserId;
                    window._kbh_meeting_demand_UserId = _res.data.demand_UserId;
                    window._kbh_meeting_id = _res.data.id;

                    // type: 2 === PublicDialog === Private Group
                    //ChatService.createPublicDialog_V2(params.occupants_ids, params.name, null)
                    //    .then((newDialog) => {

                    //        ChatService.sendChatAlertOnCreate_V2(newDialog);

                    //        //const ccData = {
                    //        //    Chat_MeetingId: newDialog.id + "",
                    //        //    Id: window._kbh_meeting_id,
                    //        //    Supply_UserId: window._kbh_meeting_supply_UserId,
                    //        //    Demand_UserId: window._kbh_meeting_demand_UserId,
                    //        //    RelatedTo_MeetingResaved_Id: "-1"
                    //        //};

                    //        (async () => {
                    //            //const ___res = await meetingService.updateMeetingWithCCId(ccData);
                    //            //if (___res.success === true) {
                    //            if (true) {
                    //                //this.props.finishLoadingAction();
                    //                //this.props.history.push("/meetings-archive");

                    //                if (this.state.meetingType === "0") {
                    //                    //const ___diaglog = await ChatService.fetchDialogs_Really_ById_V2(ccData.Chat_MeetingId);
                    //                    //ChatService.setSelectDialog(___diaglog);
                    //                    //ChatService.setSelectDialog(newDialog);
                    //                    this.props.finishLoadingAction();
                    //                    //this.props.history.push("/message-chat/" + newDialog.id);
                    //                    this.props.history.push("/meetings-reserve-archive");
                    //                }
                    //                if (this.state.meetingType === "1") {
                    //                    this.props.finishLoadingAction();
                    //                    //this.startVoiceCall(window._kbh_chat_Supply_OccupantId);
                    //                    this.props.history.push("/meetings-reserve-archive");
                    //                }
                    //                if (this.state.meetingType === "2") {
                    //                    this.props.finishLoadingAction();
                    //                    //this.startCall(window._kbh_chat_Supply_OccupantId);
                    //                    this.props.history.push("/meetings-reserve-archive");
                    //                }

                    //            }
                    //            else {
                    //                this.props.finishLoadingAction();
                    //                toast.error("اخطار : " + ___res.message);
                    //            }
                    //        })();

                    //    })
                    //    .catch((error) => {
                    //        this.props.finishLoadingAction();
                    //        toast.error("اخطار : " + error);
                    //    });


                    this.props.finishLoadingAction();
                    this.props.history.push("/meetings-reserve-archive");

                }
                else {
                    this.props.finishLoadingAction();
                    this.props.history.push("/meetings-archive");
                }


            }

            this.props.finishLoadingAction();
            this.props.history.push("/meetings-reserve-archive");
        }
        else {
            this.props.finishLoadingAction();
            toast.error(_res.message);
            //this.props.history.push("/meetings-reserve-archive");
        }
    }


    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="col-12 mx-auto px-0 px-md-3">

                    <LoadingPage />

                    <div className="ticketing-wrapper">

                        <div className="ticketing-body">

                            <div className="ticketing-date">
                                <p className="miladi-date">
                                    { /* Thursday - 2020 06 August*/}
                                </p>
                            </div>

                            { /* ----------- key={item._id} must be for list in react ---------------- */}
                            {this.state.reserves.map(item => (

                                <React.Fragment>

                                    <div key={item.id}>
                                        {(item.from_Person_Id === item.demand_UserId) &&


                                            <div className="tickets-left-wrapper" key={item.id}>
                                                <div className="ticket-avatar" style={{ backgroundImage: "url(" + baseUrlAddress.getFilesBaseUrl() + convertUrltoEncode(item.demand_Avatar) + ")" }}>
                                                </div>
                                                <div className="ticket-left reserve-offer">
                                                    <div className="reserve-offer-body">

                                                        <ul>
                                                            {/*<li>*/}
                                                            {/*    نوع جلسه :*/}
                                                            {/*    <span>*/}
                                                            {/*        {(item.meetingType === 0) &&*/}
                                                            {/*            <span>*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                چت*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                <i className="fas fa-comments"></i>*/}
                                                            {/*            </span>*/}
                                                            {/*        }*/}
                                                            {/*        {(item.meetingType === 1) &&*/}
                                                            {/*            <span>*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                تماس صوتی*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                <i className="fas fa-phone-volume"></i>*/}
                                                            {/*            </span>*/}
                                                            {/*        }*/}
                                                            {/*        {(item.meetingType === 2) &&*/}
                                                            {/*            <span>*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                تماس  تصویری*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                <i className="fas fa-video"></i>*/}
                                                            {/*            </span>*/}
                                                            {/*        }*/}
                                                            {/*    </span>*/}
                                                            {/*</li>*/}
                                                            <li>
                                                                موضوع :
                                                                &nbsp;
                                                                <span>
                                                                    {item.consultantTypeName}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                توضیحات :
                                                                &nbsp;
                                                                <span>
                                                                    {item.statusMessage}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                روز پیشنهادی :
                                                                <span>
                                                                    &nbsp;
                                                                    {item.start_Proposal_FullDate_InLocal_Shamsi}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                زمان پیشنهادی :
                                                                <span>
                                                                    &nbsp;
                                                                    {" " + item.start_Proposal_FromHour_InLocal + " "}
                                                                    &nbsp;
                                                                    الی
                                                                    &nbsp;
                                                                    {" " + item.start_Proposal_ToHour_InLocal + " "}
                                                                </span>
                                                            </li>

                                                        </ul>

                                                        {(false) &&

                                                            <div className="w-100">
                                                                <div className="row p-0 m-0">
                                                                    <div className="col-6 px-1">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-acc mb-0"
                                                                        >
                                                                            پذیرفتن
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-6 px-1">
                                                                        <Link
                                                                            to={"/xxxxxxxxxxxxxxxxxx/" + item.id + ""}
                                                                            className="btn btn-dec mb-0"
                                                                        >
                                                                            رد کردن
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>

                                                {(item.isFinalAccepted_Status === false) &&

                                                    <div className="ticket-left-time-options">
                                                        <div className="collapse show" id="accept">
                                                            {/*<div className="alert alert-success text-center mb-0" role="alert">
                                                                انتخاب ساعت شروع جلسه   
                                                            </div>*/}

                                                            {(item.isOnlineRequest === false) &&
                                                                <>
                                                                    {item.stepLong_List.map(stepItem => (
                                                                        <>
                                                                            <div className="form-check form-check-inline w-100 m-0">
                                                                                {/*<input className="form-check-input" type="radio" name="Options" id="1" value="option1" />*/}
                                                                                <label className="form-check-label w-100" for="1">
                                                                                    <button type="button" className="btn time-options" onClick={(e) => this.acceptReservationHandle(e, stepItem.stepLong_Id, item.id, item.meetingType, item.demand_UserId)}>
                                                                                        تایید جلسه از ساعت
                                                                                        &nbsp;

                                                                                        {stepItem.startTime_InLocal_Shamsi}
                                                                                        &nbsp;
                                                                                        الی
                                                                                        &nbsp;
                                                                                        {stepItem.finishTime_InLocal_Shamsi}
                                                                                        &nbsp;

                                                                                        <i className="far fa-clock"></i>
                                                                                    </button>
                                                                                </label>
                                                                            </div>
                                                                        </>
                                                                    ))}

                                                                </>
                                                            }

                                                            {(item.isOnlineRequest === true) &&
                                                                <div className="form-check form-check-inline w-100 m-0">
                                                                    {/*<input className="form-check-input" type="radio" name="Options" id="1" value="option1" />*/}
                                                                    <label className="form-check-label w-100" for="1">
                                                                        <button type="button" className="btn time-options" onClick={(e) => this.acceptReservationHandle(e, "0", item.id, item.meetingType, item.demand_UserId)}>
                                                                            تایید شروع جلسه از همین لحظه تا یک ساعت آینده
                                                                            <i className="far fa-clock"></i>
                                                                        </button>
                                                                    </label>
                                                                </div>
                                                            }

                                                            <div className="form-check form-check-inline w-100 m-0">
                                                                {/*<input className="form-check-input" type="radio" name="Options" id="3" value="option1" />*/}
                                                                <label className="form-check-label w-100" for="1">
                                                                    <button type="button" className="btn time-options rej-it" onClick={(e) => this.acceptReservationHandle(e, "deny", item.id, item.meetingType, item.demand_UserId)}>
                                                                        رد کردن درخواست رزرو جلسه
                                                                    </button>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                }

                                            </div>



                                        }


                                        {(item.from_Person_Id === item.supply_UserId) &&


                                            <div className="tickets-right-wrapper" key={item.id}>
                                                <div className="ticket-avatar" style={{ backgroundImage: "url(" + baseUrlAddress.getFilesBaseUrl() + convertUrltoEncode(item.supply_Avatar) + ")" }}>
                                                </div>
                                                <div className="ticket-right reserve-offer">
                                                    <div className="reserve-offer-body">
                                                        <ul>
                                                            {/*<li>*/}
                                                            {/*    نوع جلسه :*/}
                                                            {/*    <span>*/}
                                                            {/*        {(item.meetingType === 0) &&*/}
                                                            {/*            <span>*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                چت*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                <i className="fas fa-comments"></i>*/}
                                                            {/*            </span>*/}
                                                            {/*        }*/}
                                                            {/*        {(item.meetingType === 1) &&*/}
                                                            {/*            <span>*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                تماس صوتی*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                <i className="fas fa-phone-volume"></i>*/}
                                                            {/*            </span>*/}
                                                            {/*        }*/}
                                                            {/*        {(item.meetingType === 2) &&*/}
                                                            {/*            <span>*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                تماس  تصویری*/}
                                                            {/*                &nbsp;*/}
                                                            {/*                <i className="fas fa-video"></i>*/}
                                                            {/*            </span>*/}
                                                            {/*        }*/}
                                                            {/*    </span>*/}
                                                            {/*</li>*/}
                                                            <li>
                                                                موضوع :
                                                                &nbsp;
                                                                <span>
                                                                    {item.consultantTypeName}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                توضیحات :
                                                                &nbsp;
                                                                <span>
                                                                    {item.statusMessage}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                روز پیشنهادی :
                                                                <span>
                                                                    &nbsp;
                                                                    {item.start_Proposal_FullDate_InLocal_Shamsi}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                زمان پیشنهادی :
                                                                <span>
                                                                    &nbsp;
                                                                    {" " + item.start_Proposal_FromHour_InLocal + " "}
                                                                    &nbsp;
                                                                    الی
                                                                    &nbsp;
                                                                    {" " + item.start_Proposal_ToHour_InLocal + " "}
                                                                </span>
                                                            </li>


                                                        </ul>

                                                        {(item.status === 3) &&

                                                            <div className="w-100">
                                                                <div className="row p-0 m-0">
                                                                    <div className="col-6 px-1">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-acc mb-0"
                                                                            data-toggle="collapse"
                                                                            data-target="#accept"
                                                                            aria-expanded="false"
                                                                            aria-controls="accept"
                                                                        >
                                                                            پذیرفتن
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-6 px-1">
                                                                        <Link
                                                                            to={"/xxxxxxxxxxxxxxxxxx/" + item.id + ""}
                                                                            className="btn btn-dec mb-0"
                                                                        >
                                                                            رد کردن
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>

                                            </div>
                                        }

                                    </div>

                                </React.Fragment>

                            ))}
                            { /* ----------- key={item._id} must be for list in react ---------------- */}

                        </div>

                        { /* 
                            <button className="btn btn-save rounded-0">
                                کنسل کردن
                            </button>
                         */}

                    </div>
                </div>

            </React.Fragment>
        );
    }
}


// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(MeetingsReserveDetail));


import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';

import PrivateRoute from "./privateRoute";

import NotFound from "../notFound/index";
import Dashbord from "../dashboard/index";
import Blog from "../blog/index";
import BlogCreate from "../blog/addNew";
//import EditProfile from "../profile/editProfile";
import EditPassword from "../profile/editPassword";
import AboutUs from "../dashboard/aboutUs";
import ContactUs from "../dashboard/contactUs";
import FAQ from "../dashboard/faq";
import MeetingsArchive from "../meeting/archive";
import MeetingsArchiveByUser from "../meeting/archiveByUser";
import MeetingsReserveArchive from "../meeting/reserveArchive";
import MeetingsReserveCalendar from "../meeting/reserveCalendar";
import MeetingsRejectedReserveArchive from "../meeting/reserveArchiveRejectedList";
import MeetingsLostedReserveArchive from "../meeting/reserveArchiveLostedList";
import MeetingsReserveDetail from "../meeting/reserveDetail";
import CategoryList from "../category/index";
import BlogDetail from "../blog/detail";
import Setting from "../profile/setting";
//import MessageChat from "../cc-message/chat/chat";
//import VideoChat from "../cc-video/VideoScreen/index";
import NotifMeetingsOnline from "../meeting/notifOnline";
import Notification from "../dashboard/notification";
import Transaction from "../transaction/index";
import FileRecord from "../fileRecord/index";
//import xxxxxxxxxxxxx from "../xxxxxxxxx/yyyyyyyyyy";


// Help Routing :
//import ParentComponent from "../myExtraDevelopmentComponent/menu3/parentComponent";
//import IdParameterDetails from "../myExtraDevelopmentComponent/menu3/idParameterDetails";
//import IdCatParameterDetails from "../myExtraDevelopmentComponent/menu3/idCatParameterDetails";
//<Route path='/pg3/:nid/:ncat' component={IdCatParameterDetails} /> {/* باید بالا تر قرار بگیره */ }
//<Route path='/pg3/:nid' component={IdParameterDetails} /> {/* باید وسط تر قرار بگیره */ }
//<Route path='/pg3' component={ParentComponent} /> {/* باید پایین تر قرار بگیره */ }
// Link Tag :
//<Link to={"/pg3/5/10?name=alireza&family=kbh&age=31"} />

class PanelAllRoutes extends Component {
    constructor(props) {
        super(props);
        //props.xyz
        //props.selectedUsersIds
        //props.closeSelect
        //props.initRemoteStreams
        //props.setLocalStream
        //props.isActiveSelect
        //props.isActiveCall
        //props.resetState
        //props.unselectUser
        //props.selectUser

        console.log("panel all route : " + this.props.selectedUsersIds.length)
        console.log("panel all route : " + this.props.isActiveCall)
    }

    render() {
        return (
            <React.Fragment>

                <Switch>

                    <PrivateRoute path="/not-found" component={NotFound} />

                    <PrivateRoute path='/' exact
                        render={(props) => <Dashbord {...this.props} />}
                    />

                    {/*<PrivateRoute path='/blog' exact*/}
                    {/*    render={(props) => <Blog {...this.props} />}*/}
                    {/*/>*/}
                    {/*<PrivateRoute path="/blog-detail/:nid" component={BlogDetail} />*/}

                    {/*<PrivateRoute path="/add-blog" component={BlogCreate} />*/}

                    {/*<PrivateRoute path='/profile' exact*/}
                    {/*    render={(props) => <EditProfile {...this.props} />}*/}
                    {/*/>*/}
                    <PrivateRoute path='/change-password' exact
                        render={(props) => <EditPassword {...this.props} />}
                    />

                    <PrivateRoute path='/setting' exact
                        render={(props) => <Setting {...this.props} />}
                    />



                    <PrivateRoute path="/meetings-archive-byuser"
                        render={(props) => <MeetingsArchiveByUser {...this.props} />}
                    />


                    <PrivateRoute path="/meetings-archive/:nid" component={MeetingsArchive} />
                    <PrivateRoute path="/meetings-archive" component={MeetingsArchive} />


                    {/* <PrivateRoute path="/meetings-reserve-archive" component={MeetingsReserveArchive} /> */}
                    <PrivateRoute path="/meetings-reserve-archive"
                        render={(props) => <MeetingsReserveArchive {...this.props} />}
                    />

                    <PrivateRoute path="/meetings-reserve-calendar"
                        render={(props) => <MeetingsReserveCalendar {...this.props} />}
                    />



                    <PrivateRoute path="/meetings-rejected-reserve-archive"
                        render={(props) => <MeetingsRejectedReserveArchive {...this.props} />}
                    />

                    <PrivateRoute path="/meetings-losted-reserve-archive"
                        render={(props) => <MeetingsLostedReserveArchive {...this.props} />}
                    />


                    <PrivateRoute path="/meetings-reserve-detail/:nid" component={MeetingsReserveDetail} />

                    <PrivateRoute path="/categories" component={CategoryList} />

                    <PrivateRoute path="/notif-meetings-online" component={NotifMeetingsOnline} />

                    {/*<PrivateRoute path="/message-chat/:nid" component={MessageChat} />*/}
                    {/* <PrivateRoute path="/video-chat/:nid" component={VideoChat} /> */}

                    {/* <PrivateRoute path="/xxxxxxxxxxxx" component={xxxxxxxxxxxxxx} /> */}
                    {/* <PrivateRoute path="/xxxxxxxxxxxx" component={xxxxxxxxxxxxxx} /> */}

                    <PrivateRoute path="/about-us" component={AboutUs} />
                    <PrivateRoute path="/contact-us" component={ContactUs} />
                    <PrivateRoute path="/faq" component={FAQ} />
                    <PrivateRoute path="/notification" component={Notification} />
                    <PrivateRoute path="/transaction" component={Transaction} />
                    <PrivateRoute path="/file-record/:nid" component={FileRecord} />


                    {/* <Redirect from="/oldPage" to="/newPage" /> */}
                    {/*<Redirect from="/blog-detail" to="/blog" />*/}
                    <Redirect from="/meetings-reserve-detail" to="/meetings-reserve-archive" />
                    <Redirect from="/file-record" to="/not-found" />
                    <Redirect to="/not-found" />

                </Switch>

            </React.Fragment>
        );
    }
}

export default PanelAllRoutes;

import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";

import * as meetingResavationService from "../../services/meeting/meetingResavationService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

import m001 from "../../theme/assets/images/m1.jpg";
import m002 from "../../theme/assets/images/m2.jpg";
import m003 from "../../theme/assets/images/m3.jpg";

class NotifMeetingsOnline extends Component {
    constructor(props) {
        super(props);

        this.state = {
            reserves: [],
            pageSize: 100,
            currentPage: 1
        };
    }

    // load ajax and get data from server
    async componentDidMount() {
        //const _List = await meetingResavationService.getList();
        //if (_List.success === true)
        //    if (_List.data !== null)
        //        this.setState({ reserves: _List.data });
    }

    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>






                <div className="doctors-page">

                    <div className="row p-0 m-0">
                        <div className="col-xl-4 col-sm-6 px-2">
                            <div className="notif-widget">
                                <div className="row p-0 m-0">
                                    <div className="col-9 p-0">
                                        <div className="row p-0 m-0">
                                            <div className="col-3 p-0 d-flex align-items-center">
                                                <div className="user-image"
                                                     style={{backgroundImage: "url(" + m001 + ")"}}>
                                                </div>
                                            </div>
                                            <div className="col-9 p-0 d-flex align-items-center">
                                                <div className="w-100 notif-name">
                                                    <h2>مبیناکوچکی</h2>
                                                    <p>
                                                        تنهایی و درخود فرورفتگی
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12 p-0">
                                                <div className="notif-widget__body w-100">
                                                    <p>
                                                        <i className="fas fa-comments"></i>
                                                        <span>
                                                        درخواست مشاوره از طریق چت
                                                                </span>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 p-0 d-flex align-items-center">
                                        <div className="w-100">
                                            <a className="btn btn-acc" >
                                                پذیرفتن
                                            </a>
                                            <a className="btn btn-dec" >
                                                رد کردن
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-6 px-2">
                            <div className="notif-widget">
                                <div className="row p-0 m-0">
                                    <div className="col-9 p-0">
                                        <div className="row p-0 m-0">
                                            <div className="col-3 p-0 d-flex align-items-center">
                                                <div className="user-image"
                                                     style={{backgroundImage: "url(" + m002 + ")"}}>
                                                </div>
                                            </div>
                                            <div className="col-9 p-0 d-flex align-items-center">
                                                <div className="w-100 notif-name">
                                                    <h2>فرهاد دهقان</h2>
                                                    <p>
                                                        تنهایی و درخود فرورفتگی
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12 p-0">
                                                <div className="notif-widget__body w-100">
                                                    <p>
                                                        <i className="fas fa-phone-volume"></i>
                                                        <span>
                                                        درخواست مشاوره از طریق تماس تلفنی
                                                                </span>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 p-0 d-flex align-items-center">
                                        <div className="w-100">
                                            <a className="btn btn-acc" >
                                                پذیرفتن
                                            </a>
                                            <a className="btn btn-dec" >
                                                رد کردن
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-6 px-2">
                            <div className="notif-widget">
                                <div className="row p-0 m-0">
                                    <div className="col-9 p-0">
                                        <div className="row p-0 m-0">
                                            <div className="col-3 p-0 d-flex align-items-center">
                                                <div className="user-image"
                                                     style={{backgroundImage: "url(" + m003 + ")"}}>
                                                </div>
                                            </div>
                                            <div className="col-9 p-0 d-flex align-items-center">
                                                <div className="w-100 notif-name">
                                                    <h2>عادل فردوسی پور</h2>
                                                    <p>
                                                        مشاوره پیش از ازدواج
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12 p-0">
                                                <div className="notif-widget__body w-100">
                                                    <p>
                                                        <i className="fas fa-video"></i>
                                                        <span>
                                                        درخواست مشاوره از طریق تماس تصویری
                                                                </span>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3 p-0 d-flex align-items-center">
                                        <div className="w-100">
                                            <a className="btn btn-acc" >
                                                پذیرفتن
                                            </a>
                                            <a className="btn btn-dec" >
                                                رد کردن
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="row p-0 m-0">

                        { /* ----------- key={item._id} must be for list in react ---------------- */}
                        { /* {this.state.reserves.map(item => (

                            <div className="col-xl-4 col-sm-6 px-2" key={item.id}>

                            </div>

                        ))} */}
                        { /* ----------- key={item._id} must be for list in react ---------------- */}

                    </div >
                </div >


            </React.Fragment >
        );
    }
}

export default NotifMeetingsOnline;

export const Dashboard = "Dashboard";
export const Categories = "Categories";
export const MeetingsReserveArchive = "MeetingsReserveArchive";
export const MeetingsReserveCalendar = "MeetingsReserveCalendar";
export const MeetingsRejectedReserveArchive = "MeetingsRejectedReserveArchive";
export const MeetingsLostedReserveArchive = "MeetingsLostedReserveArchive";
export const MeetingsArchive = "MeetingsArchive";
export const Blog = "Blog";
export const Transaction = "Transaction";
export const Faq = "Faq";
export const AboutUs = "AboutUs";
export const ContactUs = "ContactUs";
export const Profile = "Profile";
export const Setting = "Setting";
export const Notification = "Notification";
export const NotFound = "NotFound";
export const BlogCreate = "BlogCreate";
export const FileRecord = "FileRecord";
//message-chat
export const MessageChat = "MessageChat";



import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, withRouter, Switch, Route, Link, Redirect } from 'react-router-dom';
//import { toast } from "react-toastify";

//import * as meetingService from "../../../services/meeting/meetingService";
//import * as baseUrlAddress from "../../../utils/urlAddress/baseUrlAddress";

////import { SafeAreaView, StatusBar } from 'react-native';
//import ConnectyCube from 'connectycube'
////import ConnectyCube from 'react-native-connectycube';
////import AwesomeAlert from 'react-native-awesome-alerts';
//import IncomingCallModal from './incoming-call-modal';
//import RTCViewGrid from './RTCViewGrid';
//import videoAuthService from '../../../services/cc-video/auth-service';
//import messageAuthService from '../../../services/cc-message/auth-service';
//import CallService from '../../../services/cc-video/call-service';
//import ToolBar from './ToolBar';
////import UsersSelect from './UsersSelect';

//import UsersService from '../../../services/cc-message/users-service';
//import store from '../../../redux/store/store';

import './index.css';
import './custom.css';
import * as currentUserHelper from "../../../helper/localStorage/currentUserHelper";

import MainLayout from "../../../components/layout/mainLayout";

class PreMainLayoutForCcVideo extends Component {
    constructor(props) {
        super(props);


        //this._session = null;
        //this.opponentsIds = [];

        //this.refToMylocalVideo = React.createRef();
        //this.refToMyremoteVideo = React.createRef();

        this.state = {
            localStream: null,
            remoteStreams: [],
            selectedUsersIds: [],
            selectedUserOnOtherSideOfCallingId: 0,
            isActiveSelect: true,
            isActiveCall: false,
            isIncomingCall: false,
            consultant: [],
            currentCall_Demand_UserId: 0,
            currentCall_Demand_UserName: "",
            currentCall_Demand_UserAvatar: "",
            currentCall_Supply_UserId: 0,
            currentCall_Supply_UserName: "",
            currentCall_Supply_UserAvatar: "",
            currentCall_Demand_ChatOpcId: 0,
            currentCall_Supply_ChatOpcId: 0,
            currentCall_Meeting_Id: 0,
            currentCall_MeetingType:0,
            fromAcceptMode: false
        };
    }

    async componentDidMount() {

        const _currentUser = currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings();
        if (_currentUser === null) {
            this.props.history.push("/login");
        }
        else {

            ////// ----------------------------------------------------------------------
            ////// ----- Intitial to Calling to "chat_Demand_OccupantId" User :
            ////// ----------------------------------------------------------------------
            ////// ----- nid == chat_Demand_OccupantId :
            //let nid = this.props.match.params.nid; // nid is string
            //if (nid === undefined || nid === "undefined" || nid === "0")
            //    this.props.history.push("/meetings-reserve-archive");
            //this.selectUser(nid);  //// ----- nid == chat_Demand_OccupantId
            ////// ----- ----------------------------------------------------------------------
            //this.selectUser(1982019);
            ////// ----- ----------------------------------------------------------------------
            ////// ----- ----------------------------------------------------------------------

            //this._setUpListeners();
        }

    }

    componentWillUnmount() {
        //CallService.stopCall();
        //// ###CHANGED:
        //try {
        //    videoAuthService.logout();
        //}
        //catch (e) {

        //}
    }

    componentDidUpdate(prevProps, prevState) {
        const currState = this.state;

        //if (prevState.remoteStreams.length === 1 &&
        //    currState.remoteStreams.length === 0)
        //{
        //    CallService.stopCall();
        //    this.resetState();

        //    //###CHANGED:
        //    setTimeout(function () {
        //        window.location.reload();
        //    }, 2000);

        //}
    }


    changeMeetingParameters = (
        demandUserId,
        demandUserName,
        demandUserAvatar,
        supplyUserId,
        supplyUserName,
        supplyUserAvatar,
        demandOccupantId,
        supplyOccupantId,
        meetingId,
        meetingType,
        chatId) => {

    //    //currentCall_Demand_UserId: 0,
    //    //currentCall_Demand_UserName: 0,
    //    //currentCall_Demand_UserAvatar: 0,
    //    //currentCall_Supply_UserId: 0,
    //    //currentCall_Supply_UserName: 0,
    //    //currentCall_Supply_UserAvatar: 0,
    //    //currentCall_Demand_ChatOpcId: 0,
    //    //currentCall_Supply_ChatOpcId: 0,
    //    //currentCall_Meeting_Id: 0,

    //    this.setState({
    //        currentCall_Demand_UserId: demandUserId,
    //        currentCall_Supply_UserId: supplyUserId,
    //        currentCall_Demand_ChatOpcId: demandOccupantId,
    //        currentCall_Supply_ChatOpcId: supplyOccupantId,
    //        currentCall_Meeting_Id: meetingId
    //    });

    //    if (demandUserName !== "") {
    //        this.setState({
    //            currentCall_Demand_UserName: demandUserName
    //        });
    //    }

    //    if (demandUserAvatar !== "") {
    //        this.setState({
    //            currentCall_Demand_UserAvatar: demandUserAvatar
    //        });
    //    }

    //    if (supplyUserName !== "") {
    //        this.setState({
    //            currentCall_Supply_UserName: supplyUserName
    //        });
    //    }

    //    if (supplyUserAvatar !== "") {
    //        this.setState({
    //            currentCall_Supply_UserAvatar: supplyUserAvatar
    //        });
    //    }

    //    if (meetingType !== 0) {
    //        this.setState({
    //            currentCall_MeetingType: meetingType
    //        });
    //    }

    };

    //showInomingCallModal = session => {
    //    this._session = session;
    //    this.setState({ isIncomingCall: true });
    //};

    //hideInomingCallModal = () => {
    //    this._session = null;
    //    this.setState({ isIncomingCall: false });
    //};

    selectUser = userId => {
    //    this.setState(prevState => ({
    //        selectedUsersIds: [...prevState.selectedUsersIds, userId],
    //    }));
    //    this.setState({ selectedUserOnOtherSideOfCallingId: userId });
    //    this.opponentsIds.push(userId);
    //    //console.log("ready to start");
    };

    unselectUser = userId => {

    //    this.setState(prevState => ({
    //        selectedUsersIds: prevState.selectedUsersIds.filter(id => userId !== id),
    //    }));

    //    //this.opponentsIds.delete(userId) :
    //    const index = this.opponentsIds.indexOf(userId);
    //    if (index > -1) {
    //        this.opponentsIds.splice(index, 1);
    //    }

    //    this.setState({ selectedUserOnOtherSideOfCallingId: 0 });

    };

    //unselectAllOtherUsers = () => {

    //    this.setState(prevState => ({
    //        selectedUsersIds: prevState.selectedUsersIds.filter(id => id !== this.state.selectedUserOnOtherSideOfCallingId),
    //    }));

    //    //this.opponentsIds.delete(this.state.selectedUserOnOtherSideOfCallingId) :
    //    const index = this.opponentsIds.indexOf(this.state.selectedUserOnOtherSideOfCallingId);
    //    if (index > -1) {
    //        this.opponentsIds.splice(index, 1);
    //    }

    //    this.setState({ selectedUserOnOtherSideOfCallingId: 0 });

    //    window.location.reload(false);

    //}

    closeSelect = () => {
        //this.setState({ isActiveSelect: false });
    };

    //setOnCall = () => {
    //    this.setState({ isActiveCall: true });
    //};

    initRemoteStreams = opponentsIds => {

        ////console.log(" initRemoteStreams .... with  opponentsIds ..... ");

        //const emptyStreams = opponentsIds.map(userId => ({
        //    userId,
        //    stream: null,
        //}));

        //this.setState({ remoteStreams: emptyStreams });
    };

    //updateRemoteStream = (userId, stream) => {
    //    this.setState(({ remoteStreams }) => {
    //        const updatedRemoteStreams = remoteStreams.map(item => {
    //            if (item.userId === userId) {
    //                return { userId, stream };
    //            }

    //            return { userId: item.userId, stream: item.stream };
    //        });

    //        return { remoteStreams: updatedRemoteStreams };
    //    });
    //};

    //removeRemoteStream = userId => {
    //    this.setState(({ remoteStreams }) => ({
    //        remoteStreams: remoteStreams.filter(item => item.userId !== userId),
    //    }));
    //};

    setLocalStream = stream => {
        //this.setState({ localStream: stream });

        //// userId 1 :
        //let localVideoElement = this.refToMylocalVideo.current;
        ////this._session.attachMediaStream(localVideoElement, stream); // this.state.localStream
        ////session.attachMediaStream(localVideoElement, stream); // this.state.localStream
        //localVideoElement.srcObject = stream; // this.state.localStream
        //localVideoElement.onloadedmetadata = function (e) {
        //    localVideoElement.play();
        //};

    };

    resetState = () => {
        //this.setState({
        //    localStream: null,
        //    remoteStreams: [],
        //    //selectedUsersIds: [],
        //    isActiveSelect: true,
        //    isActiveCall: false,
        //});
        //this.unselectAllOtherUsers();
    };

    //_setUpListeners() {
    //    try {
    //        //ConnectyCube.videochat.onCallListener = this._onCallListener.bind(this);
    //        //ConnectyCube.videochat.onAcceptCallListener = this._onAcceptCallListener.bind(this);
    //        //ConnectyCube.videochat.onRejectCallListener = this._onRejectCallListener.bind(this);
    //        //ConnectyCube.videochat.onStopCallListener = this._onStopCallListener.bind(this);
    //        //ConnectyCube.videochat.onUserNotAnswerListener = this._onUserNotAnswerListener.bind(this);
    //        //ConnectyCube.videochat.onRemoteStreamListener = this._onRemoteStreamListener.bind(this);
    //        console.log(" video : finish  _setUpListeners ........ ..........");
    //    }
    //    catch (e) {
    //        //this.props.history.push("/login");
    //        console.log("video : _setUpListeners FAILED !!!!!!!!!!!! FAILED !!!!!!!!!!!!")
    //    }
    //}

    //_onPressAccept = () => {
    //    CallService.acceptCall(this._session)
    //        .then(stream => {

    //            const { opponentsIDs, initiatorID, currentUserID } = this._session;

    //            const opponentsIds = [initiatorID, ...opponentsIDs].filter(
    //                userId => currentUserID !== userId,
    //            );

    //            this.
    //                changeMeetingParameters(
    //                    0,
    //                    "",
    //                    "",
    //                    0,
    //                    "",
    //                    "",
    //                    initiatorID,
    //                    currentUserID,
    //                    0,
    //                    0,
    //                    0
    //                );

    //            this.initRemoteStreams(opponentsIds);
    //            this.setLocalStream(stream);
    //            this.closeSelect();
    //            this.hideInomingCallModal();

    //            window.that = this;

    //            (async () => {
    //                const reqData = {
    //                    Id: 0,
    //                    Demand_UserId: 0,
    //                    Supply_UserId: 0,
    //                    Chat_Demand_OccupantId: initiatorID + "",
    //                    Chat_Supply_OccupantId: currentUserID + ""
    //                };
    //                const _res = await meetingService.updateCurrentMeeting(reqData);
    //                if (_res.success === true) {
    //                    //    if (_res.data.type === "LackOfCharge") {
    //                    //        toast.error("Error: " + _res.message);
    //                    //    }
    //                    //    if (_res.data.type === "LackOfTime") {
    //                    //        toast.error("Error: " + _res.message);
    //                    //    }
    //                    //    if (_res.data.type === "TimeOut") {
    //                    //        toast.error("Error: " + _res.message);

    //                    //        //###CHANGED:
    //                    //        setTimeout(function () {
    //                    //            window.that._onPressReject();
    //                    //            window.location.reload();
    //                    //        }, 2500);
    //                    //        setTimeout(function () {
    //                    //            window.location.reload();
    //                    //        }, 4000);

    //                    //    }

    //                    window.that.changeMeetingParameters(
    //                            _res.data.meetInfo.demand_UserId,
    //                            _res.data.meetInfo.demand_UserName,
    //                            _res.data.meetInfo.demand_UserAvatar,
    //                            _res.data.meetInfo.supply_UserId,
    //                            _res.data.meetInfo.supply_UserName,
    //                            _res.data.meetInfo.supply_UserAvatar,
    //                            _res.data.meetInfo.chat_Demand_OccupantId,
    //                            _res.data.meetInfo.chat_Supply_OccupantId,
    //                            _res.data.meetInfo.id,
    //                            _res.data.meetInfo.meetingType,
    //                            0
    //                        );

    //                    window.that.setState({
    //                        fromAcceptMode: true
    //                    });





    //                }
    //                else {
    //                    toast.error("اخطار : " + _res.message);

    //                    //###CHANGED:
    //                    setTimeout(function () {
    //                        window.that._onPressReject();
    //                        window.location.reload();
    //                    }, 2500);
    //                    setTimeout(function () {
    //                        window.location.reload();
    //                    }, 4000);

    //                }
    //            })();


    //        });
    //};

    //_onPressReject = () => {
    //    CallService.rejectCall(this._session);
    //    this.hideInomingCallModal();
    //    setTimeout(function () {
    //        window.location.reload();
    //    }, 2000);
    //};

    //_onCallListener = (session, extension) => {
    //    console.log(" calllllllling ............................. ");
    //    CallService.processOnCallListener(session)
    //        .then(() => this.showInomingCallModal(session))
    //        .catch(this.hideInomingCallModal);
    //};

    //_onAcceptCallListener = (session, userId, extension) => {
    //    CallService.processOnAcceptCallListener(session, userId, extension)
    //        .then(this.setOnCall)
    //        .catch(this.hideInomingCallModal);
    //};

    //_onRejectCallListener = (session, userId, extension) => {
    //    CallService.processOnRejectCallListener(session, userId, extension)
    //        .then(() => {
    //            this.removeRemoteStream(userId);
    //            this.hideInomingCallModal();
    //            setTimeout(function () {
    //                window.location.reload();
    //            }, 2000);
    //        })
    //        .catch(this.hideInomingCallModal);
    //};

    //_onStopCallListener = (session, userId, extension) => {
    //    const isStoppedByInitiator = session.initiatorID === userId;

    //    CallService.processOnStopCallListener(userId, isStoppedByInitiator)
    //        .then(() => {
    //            //if (isStoppedByInitiator) {
    //            //    this.resetState();
    //            //} else {
    //            //    this.removeRemoteStream(userId);
    //            //}
    //            try {
    //                this.removeRemoteStream(userId);
    //                this.resetState();
    //                this.hideInomingCallModal();
    //                setTimeout(function () {
    //                    window.location.reload();
    //                }, 2000);
    //            } catch (e) {
    //                console.error("......................................_onStopCallListener........................................");
    //                console.error(e);
    //                //window.location.reload(true);
    //            }
    //        })
    //        .catch(this.hideInomingCallModal);
    //};

    //_onUserNotAnswerListener = (session, userId) => {
    //    CallService.processOnUserNotAnswerListener(userId)
    //        .then(() => {
    //            this.removeRemoteStream(userId);
    //            this.hideInomingCallModal();
    //            setTimeout(function () {
    //                window.location.reload();
    //            }, 2000);
    //        })
    //        .catch(this.hideInomingCallModal);
    //};

    //_onRemoteStreamListener = (session, userId, stream) => {
    //    CallService.processOnRemoteStreamListener(userId)
    //        .then(() => {
    //            this.updateRemoteStream(userId, stream);
    //            this.setOnCall();
    //        })
    //        .catch(this.hideInomingCallModal);
    //};



    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :


        const {
            localStream,
            remoteStreams,
            selectedUsersIds,
            isActiveSelect,
            isActiveCall,
            isIncomingCall,
            currentCall_Demand_ChatOpcId,
            currentCall_Supply_ChatOpcId,
            currentCall_Demand_UserId,
            currentCall_Demand_UserName,
            currentCall_Demand_UserAvatar,
            currentCall_Supply_UserId,
            currentCall_Supply_UserName,
            currentCall_Supply_UserAvatar,
            currentCall_Meeting_Id,
            currentCall_MeetingType,
            fromAcceptMode
        } = this.state;

        //const initiatorName = isIncomingCall
        //    ? CallService.getUserById(this._session.initiatorID, 'name')
        //    : '';
        //const localStreamItem = localStream
        //    ? [{ userId: 'localStream', stream: localStream }]
        //    : [];
        //const streams = [...remoteStreams, ...localStreamItem];

        //CallService.setSpeakerphoneOn(remoteStreams.length > 0);

        return (
            <React.Fragment>


                <MainLayout
                    selectedUsersIds={selectedUsersIds}
                    closeSelect={this.closeSelect}
                    initRemoteStreams={this.initRemoteStreams}
                    setLocalStream={this.setLocalStream}
                    isActiveSelect={isActiveSelect}
                    isActiveCall={isActiveCall}
                    resetState={this.resetState}
                    unselectUser={this.unselectUser}
                    selectUser={this.selectUser}
                    currentCall_Demand_ChatOpcId={currentCall_Demand_ChatOpcId}
                    currentCall_Supply_ChatOpcId={currentCall_Supply_ChatOpcId}
                    currentCall_Demand_UserId={currentCall_Demand_UserId}
                    currentCall_Demand_UserName={currentCall_Demand_UserName}
                    currentCall_Demand_UserAvatar={currentCall_Demand_UserAvatar}
                    currentCall_Supply_UserId={currentCall_Supply_UserId}
                    currentCall_Supply_UserName={currentCall_Supply_UserName}
                    currentCall_Supply_UserAvatar={currentCall_Supply_UserAvatar}
                    currentCall_Meeting_Id={currentCall_Meeting_Id}
                    currentCall_MeetingType={currentCall_MeetingType}
                    fromAcceptMode={fromAcceptMode}
                    changeMeetingParameters={this.changeMeetingParameters}
                />



                {/*{*/}
                {/*    (!isActiveSelect)*/}
                {/*        ?*/}
                {/*        (*/}
                {/*            <div*/}
                {/*                className="cooper-video-call"*/}
                {/*                style={{*/}
                {/*                    position: "relative",*/}
                {/*                    zIndex: "1",*/}
                {/*                    width: "100%",*/}
                {/*                    height: "100vh",*/}
                {/*                    margin: "auto",*/}
                {/*                    overflow: "hidden"*/}
                {/*                }}*/}
                {/*            >*/}

                {/*                <RTCViewGrid*/}
                {/*                    streams={streams}*/}
                {/*                    Demand_UserName={currentCall_Demand_UserName}*/}
                {/*                    Demand_UserAvatar={currentCall_Demand_UserAvatar}*/}
                {/*                    Supply_UserName={currentCall_Supply_UserName}*/}
                {/*                    Supply_UserAvatar={currentCall_Supply_UserAvatar}*/}
                {/*                    MeetingType={currentCall_MeetingType}*/}
                {/*                    style={{*/}
                {/*                        position: "relative",*/}
                {/*                        zIndex: "1",*/}
                {/*                        width: "100%",*/}
                {/*                        height: "100vh",*/}
                {/*                        margin: "auto",*/}
                {/*                        overflow: "hidden"*/}
                {/*                    }}*/}
                {/*                />*/}



                {/*                <ToolBar*/}
                {/*                    selectedUsersIds={selectedUsersIds}*/}
                {/*                    closeSelect={this.closeSelect}*/}
                {/*                    initRemoteStreams={this.initRemoteStreams}*/}
                {/*                    setLocalStream={this.setLocalStream}*/}
                {/*                    isActiveSelect={isActiveSelect}*/}
                {/*                    isActiveCall={isActiveCall}*/}
                {/*                    resetState={this.resetState}*/}
                {/*                    localStream={localStream}*/}
                {/*                    currentCall_Demand_ChatOpcId={currentCall_Demand_ChatOpcId}*/}
                {/*                    currentCall_Supply_ChatOpcId={currentCall_Supply_ChatOpcId}*/}
                {/*                    currentCall_Demand_UserId={currentCall_Demand_UserId}*/}
                {/*                    currentCall_Demand_UserName={currentCall_Demand_UserName}*/}
                {/*                    currentCall_Demand_UserAvatar={currentCall_Demand_UserAvatar}*/}
                {/*                    currentCall_Supply_UserId={currentCall_Supply_UserId}*/}
                {/*                    currentCall_Supply_UserName={currentCall_Supply_UserName}*/}
                {/*                    currentCall_Supply_UserAvatar={currentCall_Supply_UserAvatar}*/}
                {/*                    currentCall_Meeting_Id={currentCall_Meeting_Id}*/}
                {/*                    currentCall_MeetingType={currentCall_MeetingType}*/}
                {/*                    fromAcceptMode={fromAcceptMode}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        )*/}
                {/*        :*/}
                {/*        (*/}
                {/*            <React.Fragment>*/}
                {/*                <MainLayout*/}
                {/*                    selectedUsersIds={selectedUsersIds}*/}
                {/*                    closeSelect={this.closeSelect}*/}
                {/*                    initRemoteStreams={this.initRemoteStreams}*/}
                {/*                    setLocalStream={this.setLocalStream}*/}
                {/*                    isActiveSelect={isActiveSelect}*/}
                {/*                    isActiveCall={isActiveCall}*/}
                {/*                    resetState={this.resetState}*/}
                {/*                    unselectUser={this.unselectUser}*/}
                {/*                    selectUser={this.selectUser}*/}
                {/*                    currentCall_Demand_ChatOpcId={currentCall_Demand_ChatOpcId}*/}
                {/*                    currentCall_Supply_ChatOpcId={currentCall_Supply_ChatOpcId}*/}
                {/*                    currentCall_Demand_UserId={currentCall_Demand_UserId}*/}
                {/*                    currentCall_Demand_UserName={currentCall_Demand_UserName}*/}
                {/*                    currentCall_Demand_UserAvatar={currentCall_Demand_UserAvatar}*/}
                {/*                    currentCall_Supply_UserId={currentCall_Supply_UserId}*/}
                {/*                    currentCall_Supply_UserName={currentCall_Supply_UserName}*/}
                {/*                    currentCall_Supply_UserAvatar={currentCall_Supply_UserAvatar}*/}
                {/*                    currentCall_Meeting_Id={currentCall_Meeting_Id}*/}
                {/*                    currentCall_MeetingType={currentCall_MeetingType}*/}
                {/*                    fromAcceptMode={fromAcceptMode}*/}
                {/*                    changeMeetingParameters={this.changeMeetingParameters}*/}
                {/*                />*/}
                {/*            </React.Fragment>*/}
                {/*        )*/}
                {/*}*/}

                {/*{*/}
                {/*    (isIncomingCall)*/}
                {/*        ?*/}
                {/*        (*/}
                {/*            <IncomingCallModal*/}
                {/*                callInitiator={initiatorName}*/}
                {/*                onCancelPressed={this._onPressReject}*/}
                {/*                onConfirmPressed={this._onPressAccept}*/}
                {/*                onDismiss={this.hideInomingCallModal}*/}
                {/*            />*/}
                {/*        )*/}
                {/*        :*/}
                {/*        (*/}
                {/*            <React.Fragment>*/}
                {/*            </React.Fragment>*/}
                {/*        )*/}
                {/*}*/}

            </React.Fragment>
        );
    }

}

const mapStateToProps = ({ users }) => ({
    users
})

export default connect(mapStateToProps)(withRouter(PreMainLayoutForCcVideo))

import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import Pushe from "pushe-webpush";

import * as profileService from "../../services/profile/profileService";
import { refreshTokenAction } from "../../redux/actions/accountActions";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import * as currentUserHelper from "../../helper/localStorage/currentUserHelper";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";


class Setting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            //timeZoneName: "",
            userName: "",
            isPushNotifActive: false,
            isLoadingPushNotif: true
        };

        //currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings();
        //if (this.props.accountState.currentUser) {
        //    this.state = {
        //        userName: this.props.accountState.currentUser.UserName
        //    };
        //}
    }

    async componentDidMount() {

        store.dispatch({
            type: menuActionTypes.Setting
        });

        this.props.startLoadingAction();


        let __userName = "";
        currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings();
        if (this.props.accountState.currentUser) {
            __userName = this.props.accountState.currentUser.UserName;
            this.setState({ userName: this.props.accountState.currentUser.UserName });
        }

        //const userData = {
        //    UserName: __userName
        //};
        //const _result = await profileService.getUserTimeZone(userData);
        //if (_result.success) {
        //    toast.success("منطقه زمانی کاربر لود گردید");
        //    this.setState({ timeZoneName: _result.data });
        //}
        //else {
        //    toast.error(_result.message);
        //}

        this.props.finishLoadingAction();


        // PushNotif :
        this.initialCheckPushNotif()

    }


    initialCheckPushNotif = () => {
        //console.log(this);
        // this == react componnet :
        var _thisComponent = this;

        setTimeout(function () {

            //console.log(this);
            // this === windows :
            // this in setTimeout === windows :

            Pushe.getPermissionState().then(permission => {
                if (permission === 'granted') {
                    _thisComponent.setState({ isPushNotifActive: true });
                    _thisComponent.setState({ isLoadingPushNotif: false });
                    //toast.success("پوش نوتیفیکیشن فعال است");
                }
                else {
                    _thisComponent.setState({ isPushNotifActive: false });
                    _thisComponent.setState({ isLoadingPushNotif: false });
                    toast.error("پوش نوتیفیکیشن غیر فعال است");
                }
            })
        }, 6000);
    }

    handleSwitchChange = () => {

        let isActive = !this.state.isPushNotifActive
        this.setState({ isPushNotifActive: isActive });

        if (isActive) {
            Pushe.init("5dn6j057lrk04oke");
            Pushe.subscribe({
                "showDialog": true,
                "icon": "https://files.pushe.co/prompt-icons/2021/07/20210713-0fd6994bfa4a4bb69c43198c209b9993.png",
                "title": "عضویت در اعلانات پنل مشاوران",
                "content": "جهت یادآوری و برقرراری جلسات مشاوره و همه اطلاع رسانی های مورد نیاز پنل مشاوره",
                "position": "top-center",
                "direction": "rtl",
                "acceptText": "قبول می کنم",
                "rejectText": "تمایلی ندارم",
                "promptTheme": "pushe-prompt-theme2",
                "mobilePosition": "top",
                "dialogRetryRate": 86400
            });
        }
        else {
            //Pushe.init("5dn6j057lrk04oke");
            Pushe.unsubscribe(); // لغو عضویت
            toast.error("پوش نوتیفیکیشن غیر فعال شد");
        }

    }





    handleNotifSwitchOn = () => {
        Pushe.init("5dn6j057lrk04oke");
        Pushe.subscribe({
            "showDialog": true,
            "icon": "https://files.pushe.co/prompt-icons/2021/07/20210713-0fd6994bfa4a4bb69c43198c209b9993.png",
            "title": "عضویت در اعلانات پنل مشاوران",
            "content": "جهت یادآوری و برقرراری جلسات مشاوره و همه اطلاع رسانی های مورد نیاز پنل مشاوره",
            "position": "top-center",
            "direction": "rtl",
            "acceptText": "قبول می کنم",
            "rejectText": "تمایلی ندارم",
            "promptTheme": "pushe-prompt-theme2",
            "mobilePosition": "top",
            "dialogRetryRate": 86400
        });
    }

    handleNotifSwitchOff = () => {
        Pushe.unsubscribe(); // لغو عضویت
        toast.error("پوش نوتیفیکیشن غیر فعال شد");
    }

    //onChangeTimeZoneHandle = async e => {

    //    this.props.startLoadingAction();


    //    this.setState({ [e.target.name]: e.target.value });

    //    const userData = {
    //        UserName: this.state.userName,
    //        TimeZoneDisplayName: e.target.value
    //    };
    //    const _result = await profileService.setUserTimeZone(userData);
    //    if (_result.success) {
    //        toast.success("منطقه زمانی کاربر با موفقیت تغییر کرد");
    //    }
    //    else
    //        toast.error(_result.message);

    //    this.props.finishLoadingAction();


    //};

    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <LoadingPage />


                <div className="col-md-6 mx-auto p-0">
                    <div className="setting-wrapper">


                        {/*
                        <div className="setting-widget">
                            
                            {
                                (this.state.isLoadingPushNotif === true)
                                    ?
                                    (
                                        <span>
                                            در حال بررسی تنظیمات پوش نوتیفیکیشن .....
                                        </span>
                                    )
                                    :
                                    (
                                        <div className="custom-control custom-switch">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customSwitch1"
                                                checked={this.state.isPushNotifActive}
                                                onChange={this.handleSwitchChange}
                                                readOnly
                                            />
                                            <label className="custom-control-label" htmlFor="customSwitch1">وضعیت پوش نوتیفیکیشن</label>
                                        </div>
                                    )
                            }
                            
                        </div>
                        */}

                        {/*
                        <div className="setting-widget">
                            <div id="calContainer w-100">
                                <div className="alert alert-success text-center" role="alert">
                                    کاربرگرامی موقعیت زمانی خود را برای هماهنگ کردن جلسات مشاوره مشخص کنید.
                                </div>
                                <label className=" control-label mb-2">مشخص کردن موقعیت زمانی</label>
                                <select className="form-control timezone-selector w-100" id="timeZoneName" name="timeZoneName" value={this.state.timeZoneName} onChange={this.onChangeTimeZoneHandle}>
                                    <option value="">انتخاب کنید ...</option>
                                    <option value="(GMT) Casablanca">(GMT) Casablanca (Morocco)</option>
                                    <option value="(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London">(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London (GMT)</option>
                                    <option value="(GMT) Monrovia, Reykjavik">(GMT) Monrovia, Reykjavik (Greenwich)</option>
                                    <option value="(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (W. Europe)</option>
                                    <option value="(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague (Central Europe)</option>
                                    <option value="(GMT+01:00) Brussels, Copenhagen, Madrid, Paris">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris (Romance)</option>
                                    <option value="(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb (Central European)</option>
                                    <option value="(GMT+01:00) West Central Africa">(GMT+01:00) West Central Africa (W. Central Africa)</option>
                                    <option value="(GMT+02:00) Amman">(GMT+02:00) Amman (Jordan)</option>
                                    <option value="(GMT+02:00) Athens, Bucharest, Istanbul">(GMT+02:00) Athens, Bucharest, Istanbul (GTB)</option>
                                    <option value="(GMT+02:00) Beirut">(GMT+02:00) Beirut (Middle East)</option>
                                    <option value="(GMT+02:00) Cairo">(GMT+02:00) Cairo (Egypt)</option>
                                    <option value="(GMT+02:00) Harare, Pretoria">(GMT+02:00) Harare, Pretoria (South Africa)</option>
                                    <option value="(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (FLE)</option>
                                    <option value="(GMT+02:00) Jerusalem">(GMT+02:00) Jerusalem (Israel)</option>
                                    <option value="(GMT+02:00) Minsk">(GMT+02:00) Minsk (E. Europe)</option>
                                    <option value="(GMT+02:00) Windhoek">(GMT+02:00) Windhoek (Namibia)</option>
                                    <option value="(GMT+03:00) Baghdad">(GMT+03:00) Baghdad (Arabic)</option>
                                    <option value="(GMT+03:00) Kuwait, Riyadh">(GMT+03:00) Kuwait, Riyadh (Arab)</option>
                                    <option value="(GMT+03:00) Moscow, St. Petersburg, Volgograd">(GMT+03:00) Moscow, St. Petersburg, Volgograd (Russian)</option>
                                    <option value="(GMT+03:00) Nairobi">(GMT+03:00) Nairobi (E. Africa)</option>
                                    <option value="(GMT+03:00) Tbilisi">(GMT+03:00) Tbilisi (Georgian)</option>
                                    <option value="(GMT+03:30) Tehran">(GMT+03:30) Tehran (Iran)</option>
                                    <option value="(GMT+04:00) Abu Dhabi, Muscat">(GMT+04:00) Abu Dhabi, Muscat (Arabian)</option>
                                    <option value="(GMT+04:00) Baku">(GMT+04:00) Baku (Azerbaijan)</option>
                                    <option value="(GMT+04:00) Port Louis">(GMT+04:00) Port Louis (Mauritius)</option>
                                    <option value="(GMT+04:00) Yerevan">(GMT+04:00) Yerevan (Caucasus)</option>
                                    <option value="(GMT+04:30) Kabul">(GMT+04:30) Kabul (Afghanistan)</option>
                                    <option value="(GMT+05:00) Ekaterinburg">(GMT+05:00) Ekaterinburg (Ekaterinburg)</option>
                                    <option value="(GMT+05:00) Islamabad, Karachi">(GMT+05:00) Islamabad, Karachi (Pakistan)</option>
                                    <option value="(GMT+05:00) Tashkent">(GMT+05:00) Tashkent (West Asia)</option>
                                    <option value="(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi (India)</option>
                                    <option value="(GMT+05:30) Sri Jayawardenepura">(GMT+05:30) Sri Jayawardenepura (Sri Lanka)</option>
                                    <option value="(GMT+05:45) Kathmandu">(GMT+05:45) Kathmandu (Nepal)</option>
                                    <option value="(GMT+06:00) Almaty, Novosibirsk">(GMT+06:00) Almaty, Novosibirsk (N. Central Asia)</option>
                                    <option value="(GMT+06:00) Astana, Dhaka">(GMT+06:00) Astana, Dhaka (Central Asia)</option>
                                    <option value="(GMT+06:30) Yangon (Rangoon)">(GMT+06:30) Yangon (Rangoon) (Myanmar)</option>
                                    <option value="(GMT+07:00) Bangkok, Hanoi, Jakarta">(GMT+07:00) Bangkok, Hanoi, Jakarta (SE Asia)</option>
                                    <option value="(GMT+07:00) Krasnoyarsk">(GMT+07:00) Krasnoyarsk (North Asia)</option>
                                    <option value="(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi (China)</option>
                                    <option value="(GMT+08:00) Irkutsk, Ulaan Bataar">(GMT+08:00) Irkutsk, Ulaan Bataar (North Asia East)</option>
                                    <option value="(GMT+08:00) Kuala Lumpur, Singapore">(GMT+08:00) Kuala Lumpur, Singapore (Singapore)</option>
                                    <option value="(GMT+08:00) Perth">(GMT+08:00) Perth (W. Australia)</option>
                                    <option value="(GMT+08:00) Taipei">(GMT+08:00) Taipei (Taipei)</option>
                                    <option value="(GMT+09:00) Osaka, Sapporo, Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo (Tokyo)</option>
                                    <option value="(GMT+09:00) Seoul">(GMT+09:00) Seoul (Korea)</option>
                                    <option value="(GMT+09:00) Yakutsk">(GMT+09:00) Yakutsk (Yakutsk)</option>
                                    <option value="(GMT+09:30) Adelaide">(GMT+09:30) Adelaide (Cen. Australia)</option>
                                    <option value="(GMT+09:30) Darwin">(GMT+09:30) Darwin (AUS Central)</option>
                                    <option value="(GMT+10:00) Brisbane">(GMT+10:00) Brisbane (E. Australia)</option>
                                    <option value="(GMT+10:00) Canberra, Melbourne, Sydney">(GMT+10:00) Canberra, Melbourne, Sydney (AUS Eastern)</option>
                                    <option value="(GMT+10:00) Guam, Port Moresby">(GMT+10:00) Guam, Port Moresby (West Pacific)</option>
                                    <option value="(GMT+10:00) Hobart">(GMT+10:00) Hobart (Tasmania)</option>
                                    <option value="(GMT+10:00) Vladivostok">(GMT+10:00) Vladivostok (Vladivostok)</option>
                                    <option value="(GMT+11:00) Magadan, Solomon Is., New Caledonia">(GMT+11:00) Magadan, Solomon Is., New Caledonia (Central Pacific)</option>
                                    <option value="(GMT+12:00) Auckland, Wellington">(GMT+12:00) Auckland, Wellington (New Zealand)</option>
                                    <option value="(GMT+12:00) Fiji, Kamchatka, Marshall Is.">(GMT+12:00) Fiji, Kamchatka, Marshall Is. (Fiji)</option>
                                    <option value="(GMT+13:00) Nuku'alofa">(GMT+13:00) Nuku'alofa (Tonga)</option>
                                    <option value="(GMT-01:00) Azores">(GMT-01:00) Azores (Azores)</option>
                                    <option value="(GMT-01:00) Cape Verde Is.">(GMT-01:00) Cape Verde Is. (Cape Verde)</option>
                                    <option value="(GMT-02:00) Mid-Atlantic">(GMT-02:00) Mid-Atlantic (Mid-Atlantic)</option>
                                    <option value="(GMT-03:00) Brasilia">(GMT-03:00) Brasilia (E. South America)</option>
                                    <option value="(GMT-03:00) Buenos Aires">(GMT-03:00) Buenos Aires (Argentina)</option>
                                    <option value="(GMT-03:00) Georgetown">(GMT-03:00) Georgetown (SA Eastern)</option>
                                    <option value="(GMT-03:00) Greenland">(GMT-03:00) Greenland (Greenland)</option>
                                    <option value="(GMT-03:00) Montevideo">(GMT-03:00) Montevideo (Montevideo)</option>
                                    <option value="(GMT-03:30) Newfoundland">(GMT-03:30) Newfoundland (Newfoundland)</option>
                                    <option value="(GMT-04:00) Atlantic Time (Canada)">(GMT-04:00) Atlantic Time (Canada) (Atlantic)</option>
                                    <option value="(GMT-04:00) La Paz">(GMT-04:00) La Paz (SA Western)</option>
                                    <option value="(GMT-04:00) Manaus">(GMT-04:00) Manaus (Central Brazilian)</option>
                                    <option value="(GMT-04:00) Santiago">(GMT-04:00) Santiago (Pacific SA)</option>
                                    <option value="(GMT-04:30) Caracas">(GMT-04:30) Caracas (Venezuela)</option>
                                    <option value="(GMT-05:00) Bogota, Lima, Quito, Rio Branco">(GMT-05:00) Bogota, Lima, Quito, Rio Branco (SA Pacific)</option>
                                    <option value="(GMT-05:00) Eastern Time (US & Canada)">(GMT-05:00) Eastern Time (US & Canada) (Eastern)</option>
                                    <option value="(GMT-05:00) Indiana (East)">(GMT-05:00) Indiana (East) (US Eastern)</option>
                                    <option value="(GMT-06:00) Central America">(GMT-06:00) Central America (Central America)</option>
                                    <option value="(GMT-06:00) Central Time (US & Canada)">(GMT-06:00) Central Time (US & Canada) (Central)</option>
                                    <option value="(GMT-06:00) Guadalajara, Mexico City, Monterrey">(GMT-06:00) Guadalajara, Mexico City, Monterrey (Central Mexico)</option>
                                    <option value="(GMT-06:00) Saskatchewan">(GMT-06:00) Saskatchewan (Canada Central)</option>
                                    <option value="(GMT-07:00) Arizona">(GMT-07:00) Arizona (US Mountain)</option>
                                    <option value="(GMT-07:00) Chihuahua, La Paz, Mazatlan">(GMT-07:00) Chihuahua, La Paz, Mazatlan (Mountain Mexico)</option>
                                    <option value="(GMT-07:00) Mountain Time (US & Canada)">(GMT-07:00) Mountain Time (US & Canada) (Mountain)</option>
                                    <option value="(GMT-08:00) Pacific Time (US & Canada)">(GMT-08:00) Pacific Time (US & Canada) (Pacific)</option>
                                    <option value="(GMT-08:00) Tijuana, Baja California">(GMT-08:00) Tijuana, Baja California (Pacific Mexico)</option>
                                    <option value="(GMT-09:00) Alaska">(GMT-09:00) Alaska (Alaskan)</option>
                                    <option value="(GMT-10:00) Hawaii">(GMT-10:00) Hawaii (Hawaiian)</option>
                                    <option value="(GMT-11:00) Midway Island, Samoa">(GMT-11:00) Midway Island, Samoa (Samoa)</option>
                                    <option value="(GMT-12:00) International Date Line West">(GMT-12:00) International Date Line West (Dateline)</option>
                                </select>
                            </div>
                        </div>
                        */}


                        <div className="setting-widget d-flex align-items-center justify-content-between">
                            <label>پوش نوتیفیکیشن</label>
                            <span>
                                <button className="btn btn-success btn-sm" onClick={this.handleNotifSwitchOn}>
                                    راه اندازی مجدد
                                </button>

                                {/*
                                &nbsp;
                                <button className="btn btn-danger btn-sm" onClick={this.handleNotifSwitchOff}>
                                    خاموش
                                </button>
                                */}

                            </span>
                        </div>
         
                        <div class="setting-widget">
                            <Link to={"/change-password"} class="btn btn-setting">
                                <i class="fas fa-lock"></i>
                                تغییر رمز عبور
                            </Link>
                        </div>
                        { /*
                        <div class="setting-widget">
                            <a href="#" class="btn btn-setting">
                                <i class="fas fa-share-square"></i>
                                معرفی به دوستان
                            </a>
                        </div>
                         */}
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

// this.props.accountState.loading
// this.props.accountState.errorMessage
// this.props.accountState.isAuthenticated
// this.props.accountState.currentUser
const map_State_to_Props = state => ({
    accountState: state.accountReducer,
    loadingState: state.loadingReducer

});

// this.props.refreshTokenAction()
const map_DispatchAction_to_Props = {
    refreshTokenAction,
    startLoadingAction,
    finishLoadingAction
};

export default connect(map_State_to_Props, map_DispatchAction_to_Props)(Setting);

import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { toast } from "react-toastify";

import * as contactService from "../../services/contact/contactService";
import * as profileService from "../../services/profile/profileService";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";


import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";


class ContactUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            mobileNo: "",
            emailAddress: "",
            commentText: "",
            userId: 0,
            userName: ""
        };

    }

    async componentDidMount() {

        store.dispatch({
            type: menuActionTypes.ContactUs
        });

        this.props.startLoadingAction();


        const _result = await profileService.getProfile();
        if (_result.success) {
            this.setState({
                firstName: _result.data.firstName,
                lastName: _result.data.lastName,
                mobileNo: _result.data.mobile,
                emailAddress: _result.data.email,
                userId: _result.data.id,
                userName: _result.data.userName,
            });
            //toast.success("User Info Loaded successfully");
        }
        else {
            toast.error(_result.message);
        }

        this.props.finishLoadingAction();

    }

    setIsUploading = (check) => {
        this.setState({ isUploading: check });
    }



    onChangeHandle = e => {
        this.setState({ [e.target.name]: e.target.value });
    };


    onSubmitHandle = async (e) => {
        e.preventDefault();

        this.props.startLoadingAction();


        const userData = {
            Name: this.state.firstName + " " + this.state.lastName,
            Email: this.state.emailAddress,
            Mobile: this.state.mobileNo,
            Content: this.state.commentText,
            Create_ByUserId: this.state.userId,
            Create_ByUserName: this.state.userName
        };

        const _result = await contactService.save(userData);
        if (_result.success) {
            toast.success(_result.message);
        }
        else {
            toast.error(_result.message);
        }

        this.props.finishLoadingAction();


    };



    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>
                <div className="doctors-page">

                    <LoadingPage />


                    <div className="col-12 px-2">
                        <div>

                            <div className="row p-0 m-0">
                                <div className="col-md-6 px-2 mb-2">
                                    <div className="contact-form-holder h-100">
                                        <div className="row p-0 m-0">
                                            <div className="col-lg-12 p-0 m-0">
                                                <div className="address-holder">
                                                    <p>
                                                        <i className="fas fa-map-marker-alt"></i>
                                                         آدرس :
                                                   <span>
                                                            تهران، میدان انقلاب، کارگر شمالی، ساختمان سامان، واحد ۳۰۱
                                                    </span>
                                                    </p>
                                                </div>
                                                <div className="address-holder">
                                                    <p>
                                                        <i className="fas fa-phone-volume"></i>
                                                   شماره تماس :
                                                   <span>
                                                            <a href="tel:09369819911">
                                                                09369819911
                                                       </a>
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="address-holder">
                                                    <p>
                                                        <i className="far fa-clock"></i>
                                                   ایمیل :
                                                        <span>
                                                            <a href="mailto:info.yariway@gmail.com">
                                                                info.yariway@gmail.com
                                                       </a>
                                                        </span>
                                                    </p>
                                                </div>

                                            </div>
                                            <div className="col-lg-12 p-0 m-0">
                                                <iframe className="map-holder" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d809.9505988950044!2d51.39006539337634!3d35.70648002646702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e01fc53b92677%3A0xfb30b6e6d0b9d457!2sSaman%20Building!5e0!3m2!1sen!2s!4v1617866085413!5m2!1sen!2s" className="map-holder" width="100%" height="200" frameborder="0" style={{ border: "0" }} allowfullscreen=""></iframe>
                                                <div className="d-flex flex-row social-icons justify-content-center">
                                                    <a href="https://www.instagram.com/yariwaycom/"><i className="fab fa-instagram"></i></a>
                                                    {/*<a href="#"><i className="fab fa-telegram-plane"></i></a>*/}
                                                    {/*<a href="#"><i className="fab fa-facebook-f"></i></a>*/}
                                                    {/*<a href="#"><i className="fab fa-linkedin-in"></i></a>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 px-2 mb-2">
                                    <div className="contact-form-holder h-100">
                                        <p>
                                            شما میتوانید از طریق فرم زیر ما را در جریان سوالات ، انتقادات و پیشنهادات خود بگذارید. کارشناسان ما در اسرع وقت به شما پاسخ خواهند داد.
                          </p>
                                        <form className="w-100" onSubmit={this.onSubmitHandle}>
                                            <p className="desc">ارسال پیام</p>
                                            <div className="form-group">

                                                <input type="text" className="form-control" value={this.state.firstName + " " + this.state.lastName} placeholder="نام و نام خانوادگی" />
                                            </div>
                                            <div className="form-group">

                                                <input className="form-control" value={this.state.mobileNo} placeholder="شماره تلفن همراه" aria-describedby="emailHelp" />

                                            </div>
                                            <div className="form-group">

                                                <textarea value={this.state.commentText} onChange={this.onChangeHandle} id="commentText" name="commentText" className="form-control" placeholder="متن نظر" rows="3"></textarea>
                                            </div>
                                            <button type="submit" className="btn btn-load-effect btn-sign-in mt-0">
                                                ارسال پیام
                              </button>

                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </React.Fragment>
        );
    }
}


// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(ContactUs));

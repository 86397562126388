import * as axiosRequest from "../../helper/axiosRequest/httpRequest";
import * as transactionUrlAddress from "../../utils/urlAddress/transactionUrlAddress";

export async function getTransactionList() {
    return await axiosRequest.HttpPost(transactionUrlAddress.transactionList, null);
}

export async function checkoutRequest() {
    return await axiosRequest.HttpPost(transactionUrlAddress.checkoutRequest, null);
}


//export const portalBaseUrl = "https://localhost:44331";
//export const apiBaseUrl = "https://localhost:44332";
//export const supplyBaseUrl = "https://localhost:44333"; // "http://localhost:3003";
//export const demandBaseUrl = "https://localhost:44334"; // "http://localhost:3004";
//export const filesBaseUrl = "https://localhost:44335"; // "http://localhost:3005";
// -----------------------------------------------------------
//export const portalBaseUrl = "https://yariway.com";
//export const apiBaseUrl = "https://api.yariway.com";
//export const supplyBaseUrl = "https://panel.yariway.com";
//export const demandBaseUrl = "https://my.yariway.com";
//export const filesBaseUrl = "https://cdn.yariway.com";
// -----------------------------------------------------------

export function getPortalBaseUrl() {
    //if (window.location.href.includes("localhost:"))
    //    return "https://localhost:44331";
    //else
    return "https://yariway.com";
}

export function getApiBaseUrl() {
    if (window.location.href.includes("localhost:"))
        return "https://localhost:44332";
    else
        return "https://api.yariway.com";
}

export function getSupplyBaseUrl() {
    //if (window.location.href.includes("localhost:44333"))
    //    return "https://localhost:44333";
    //else if (window.location.href.includes("localhost:3003"))
    //    return "http://localhost:3003";
    //else
    return "https://panel.yariway.com";
}

export function getDemandBaseUrl() {
    //if (window.location.href.includes("localhost:44334"))
    //    return "https://localhost:44334";
    //else if (window.location.href.includes("localhost:3004"))
    //    return "http://localhost:3004";
    //else
    return "https://my.yariway.com";
}


export function getFilesBaseUrl() {
    //if (window.location.href.includes("localhost:"))
    //    return "https://localhost:44335";
    //else
    return "https://cdn.yariway.com";
}



export function getCallsBaseUrl() {
    //if (window.location.href.includes("localhost:"))
    //    return "http://localhost:8080";
    //else
    return "https://calls.yariway.com";
}

import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as blogService from "../../services/blog/blogService";
import * as commentService from "../../services/comment/commentService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import isNullOrWhiteSpaceOrEmpty from "../../helper/validation/isNullOrWhiteSpaceOrEmpty";
import CommentsListBox from "./commentList";
import { toast } from "react-toastify";
import convertUrltoEncode from "../../helper/validation/convertUrltoEncode";



import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";

class BlogDetail extends Component {
    constructor(props) {
        super(props);

        // برای بار اولی که اپ بالا می آید
        this.state = {
            commentText: "",
            isLoading: false,
            blog: {},
            likeCount: 0
        };
    }

    // load ajax and get data from server
    async componentDidMount() {
        let nid = this.props.match.params.nid; // nid is string
        if (nid === undefined || nid === "undefined" || nid === "0")
            this.props.history.push("/blog");

        this.props.startLoadingAction();

        const reqData = { Id: nid }; // Id is string
        const _obj = await blogService.getBlogById(reqData);

        if (_obj.success === true) {
            if (_obj.data !== null) {
                this.setState({ blog: _obj.data });
                this.setState({ likeCount: _obj.data.count_Like });
            }
            else {
                this.props.finishLoadingAction();
                this.props.history.push("/blog");
            }
        }
        else {
            this.props.finishLoadingAction();
            this.props.history.push("/blog");
        }

        this.props.finishLoadingAction();

        store.dispatch({
            type: menuActionTypes.Blog
        });
    }

    setIsLoading = (check) => {
        this.setState({ isLoading: check });
    }

    onChangeHandle = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmitHandle = async (e) => {
        e.preventDefault();

        this.setIsLoading(true);

        const commentData = {
            ConceptTypeId: 1,
            ConceptTypeName: "Article",
            ConceptId: this.state.blog.id,
            CommentText: this.state.commentText,
        };

        const _obj = await commentService.setComment(commentData);

        if (_obj.success === true) {
            this.setIsLoading(false);
            this.setState({ commentText: "" });
            toast.success("کامنت با موفقیت ثبت گردید");
        }
        else {
            this.setIsLoading(false);
            this.setState({ commentText: "" });
            toast.error("خطایی در ثبت کامنت رخ داده، بعدا مجددا تلاش نمایید");
        }

    };




    onLikeHandle = async (e) => {
        e.preventDefault();

        this.setIsLoading(true);

        const likeData = {
            ConceptTypeId: 1,
            ConceptTypeName: "Article",
            ConceptId: this.state.blog.id
        };

        const _obj = await commentService.setLike(likeData);
        if (_obj.success === true) {
            this.setIsLoading(false);
            toast.success(_obj.message);
            this.setState({ likeCount: this.state.likeCount + 1 });
        }
        else {
            this.setIsLoading(false);
            toast.error(_obj.message);
        }

    };



    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        //<p dangerouslySetInnerHTML={{ __html: this.state.blog.description }} />
        //<div className="doctor-image mx-auto" style={{ backgroundImage: "url(" + baseUrlAddress.getFilesBaseUrl() + convertUrltoEncode(this.state.blog.avatar) + ")" }}>
        //{this.state.blog.title}

        return (
            <React.Fragment>

                <div className="blog-single-page">

                    <LoadingPage />

                    <div className="post-wrapper blog-single">

                        <h5 className="card-title">
                            <br />
                            {this.state.blog.title}
                        </h5>
                        <div className="col-md-6 p-0 mx-auto">
                            {!isNullOrWhiteSpaceOrEmpty(this.state.blog.image) &&
                                <>
                                    {(this.state.blog.image !== " - ") &&
                                        <img src={baseUrlAddress.getFilesBaseUrl() + this.state.blog.image} className="card-img" alt={this.state.blog.title} />
                                    }
                                </>
                            }

                            {!isNullOrWhiteSpaceOrEmpty(this.state.blog.audio) &&
                                <audio
                                    controls
                                    src={baseUrlAddress.getFilesBaseUrl() + this.state.blog.audio}
                                />
                            }

                            {!isNullOrWhiteSpaceOrEmpty(this.state.blog.video) &&
                                <video
                                    controls
                                    src={baseUrlAddress.getFilesBaseUrl() + this.state.blog.video}
                                />
                            }
                        </div>
                        <div className="post-text">
                            <div dangerouslySetInnerHTML={{ __html: this.state.blog.description }} />
                        </div>
                        { /*
                        <div className="post-author">
                            <div className="row p-0 m-0">
                                <div className="col-md-2 p-0 d-flex align-items-center justify-content-center">
                                    <a href="#">
                                        <div className="img-wrapper">
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-10 p-0 text-center text-md-right">
                                    <h2 className="text-center text-md-right">کیمیا کوچکی</h2>
                                    <span className="author-position">
                                         روانشناس خانواده
                                        </span>
                                    <p>
                                           <span>کارشناسی ارشد روانشناسی عمومی
                                            درمانگر اختلالات فردی در حوزه های استرس، اضطراب و درمانگر اختلالات فردی در حوزه افسردگی</span>
                                        ...
                                    </p>
                                    <div
                                        className="mt-3 mb-2 w-100 d-flex justify-content-center justify-content-md-start">
                                        <a href="#" className="btn all-this-author">
                                            مشاهده بیشتر از این نویسنده
                                            ...
                                        </a>
                                    </div>

                                </div>

                            </div>
                        </div>
                        */ }

                        <div className="post-actions">
                            <div className="row p-0 m-0">

                                <div className="col-6 p-0">
                                    <div className="action-icon row p-0 m-0">
                                        { /*
                                        <a href="#" className="bookmark">
                                            <i className="fas fa-bookmark"></i>
                                        </a>
                                        */ }

                                        <div>
                                            <p>
                                                {this.state.blog.createDateTimeString}
                                            </p>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-6 p-0">
                                    <div className="action-icon row p-0 m-0 d-flex justify-content-end">
                                        <a className="ml-2 like" onClick={(e) => this.onLikeHandle(e)} >
                                            {this.state.likeCount}
                                            <i className="fas fa-heart mr-2"></i>
                                        </a>
                                        <a className="cm">
                                            {this.state.blog.count_Comment}
                                            <i className="fas fa-comment"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="comments-wrapper">
                        <div className="col-md-10 mx-auto p-0">
                            <form className="w-100" onSubmit={this.onSubmitHandle}>
                                <p className="desc">افزودن نظر</p>
                                {/* 
                                <div className="form-group">
                                    <div className="cap-focus">
                                        <label>نام و نام خانوادگی</label>
                                        <input type="text" className="form-control login-input" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="cap-focus">
                                        <label>ایمیل یا شماره موبایل</label>
                                        <input type="email" className="form-control login-input" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                */}
                                <div className="form-group">
                                    <div className="cap-focus">
                                        <textarea
                                            className="form-control login-input"
                                            rows="3"
                                            name="commentText"
                                            value={this.state.commentText}
                                            onChange={this.onChangeHandle}
                                            placeholder="متن نظر"
                                        />
                                    </div>
                                </div>
                                <div className="w-100 d-flex justify-content-end">
                                    <button
                                        disabled={this.state.isLoading}
                                        type="submit"
                                        className="btn btn-next-step">
                                        ثبت نظر
                                    </button>
                                </div>

                            </form>
                            <p className="desc">نظرات کاربران</p>

                            {!isNullOrWhiteSpaceOrEmpty(this.state.blog.commentsList) &&
                                <CommentsListBox comments={this.state.blog.commentsList} />
                            }

                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

// this.props.loadingState.isLoading
const map_State_to_Props = state => ({
    loadingState: state.loadingReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(BlogDetail));

import React, { Component } from "react";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import * as faqService from "../../services/faq/faqService";


class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseArray: [],
            //[
            //    {
            //        id: "collapseOne",
            //        isShow: false,
            //        collapseMode: "collapse"
            //    },
            //    {
            //        id: "collapseTwo",
            //        isShow: false,
            //        collapseMode: "collapse"
            //    },
            //    {
            //        id: "collapseThree",
            //        isShow: false,
            //        collapseMode: "collapse"
            //    }
            //]
        };
    }

    async componentDidMount() {

        store.dispatch({
            type: menuActionTypes.Faq
        });

        //this.props.startLoadingAction();
        const _obj = await faqService.getFaqList();
        if (_obj.success === true) {
            if (_obj.data !== null) {
                this.setState({ collapseArray: _obj.data });
            }
        }
        //this.props.finishLoadingAction();

    }

    handleCollapse = (tagId) => {

        let tempCopyCollapseArray = [...this.state.collapseArray];

        tempCopyCollapseArray.forEach(function (item, index, array) {
            ////array[index] ======= item
            if (item.id === tagId) {
                if (item.isShow === true) {
                    item.collapseMode = "collapse";
                    item.isShow = false;
                }
                else {
                    item.collapseMode = "collapse show";
                    item.isShow = true;
                }
            }
            else {
                item.collapseMode = "collapse";
                item.isShow = false;
            }
        })

        this.setState({ collapseArray: tempCopyCollapseArray });
    }


    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="doctors-page">
                    <div className="col-12 pt-3">
                        <div className="accordion" id="accordionExample">


                            {this.state.collapseArray.length === 0
                                ?
                                (
                                    <>
                                        <div className="col-12 mx-auto">
                                            <br />
                                            <center > هیچ پرسش و پاسخی وجود ندارد </center>
                                            <br />
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>

                                        { /* ----------- key={item._id} must be for list in react ---------------- */}
                                        {this.state.collapseArray.map(item => (
                                            <>
                                                <div className="card" key={item.id}>
                                                    <div className="card-header" id={"head" + item.id} >
                                                        <button className="btn btn-faq" type="button" data-toggle="collapse" data-target={"#" + item.id} aria-expanded="false" aria-controls={item.id}
                                                            onClick={() => this.handleCollapse("" + item.id + "")}
                                                        >
                                                            {item.title}
                                                            <i className="fas fa-plus"></i>
                                                        </button>
                                                    </div>

                                                    <div id={item.id} class={this.state.collapseArray.find(x => x.id == item.id).collapseMode} aria-labelledby={"head" + item.id} data-parent="#accordionExample">
                                                        <div className="card-body">
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                        { /* ----------- key={item._id} must be for list in react ---------------- */}

                                    </>
                                )
                            }

                        </div>

                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default FAQ;

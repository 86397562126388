import React, { Component, Fragment, useState, useEffect, useCallback, useMemo, useRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import convertUrltoEncode from "../../helper/validation/convertUrltoEncode";

import * as meetingResavationService from "../../services/meeting/meetingResavationService";
import * as consultantService from "../../services/consultant/consultantService";
import * as meetingService from "../../services/meeting/meetingService";
import * as onlineService from "../../services/online/onlineService";
import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

import store from '../../redux/store/store';
import * as menuActionTypes from "../../redux/actionTypes/menuActionTypes";

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";


import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import { Calendar } from '@fullcalendar/core';
import faLocale from '@fullcalendar/core/locales/fa';
//import moment from 'moment';
//import moment from 'moment-timezone';
import moment from 'moment-jalaali';





class MeetingsReserveCalendar extends Component {


    constructor(props) {
        super(props);

        this.state = {
            eventsList: []
        };
    }


    // load ajax and get data from server
    async componentDidMount() {

        store.dispatch({
            type: menuActionTypes.MeetingsReserveCalendar
        });

        // let jsonList = [
        //     { groupId: '01', id: '0002', title: 'event 2', date: '2024-09-17T14:30', backgroundColor: '#368e96', classNames: ['calendarKbhClassName1', 'calendarKbhClassName2'] },
        //     { groupId: '01', id: '0002', title: 'event 3', date: '2024-09-18T16:30', backgroundColor: '#368e96', classNames: ['calendarKbhClassName1', 'calendarKbhClassName2'] },
        // ];

        this.props.startLoadingAction();

        const _List = await meetingResavationService.getCalendarList();
        if (_List.success === true)
            if (_List.data !== null)
                this.setState({ eventsList: _List.data });

        this.props.finishLoadingAction();

    }



    handleDateSelect = (selectInfo) => {
        // let title = prompt('Please enter a new title for your event')
        // let calendarApi = selectInfo.view.calendar

        // calendarApi.unselect() // clear date selection

        // if (title) {
        //     calendarApi.addEvent({
        //         id: Math.random(0, 100000000) + '',
        //         title,
        //         start: selectInfo.startStr,
        //         end: selectInfo.endStr,
        //         allDay: selectInfo.allDay
        //     })
        // }
    }

    handleDateClick = (arg) => {
        // alert(arg.dateStr);
        // console.log(arg);
    }

    handleEventClick = (clickInfo) => {
        let message = " جلسه در زمان '" + clickInfo.event.extendedProps.dateShamsi + "' با کاربر '" + clickInfo.event.title + "' و موضوع '" + clickInfo.event.extendedProps.typeName + "' است ";
        toast.success(message);
        //alert(message);
        //console.log(clickInfo);
        // if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        //     clickInfo.event.remove()
        // }
    }



    renderEventContent = (eventInfo) => {
        //console.log(eventInfo);
        return (
            <>
                <span style={{ padding: "0px 5px" }}>
                    {eventInfo.timeText}
                    &nbsp; &nbsp;
                    {eventInfo.event.title}
                </span>
            </>
        )
    }




    renderMoreLinkContent = (arg) => {
        //console.log(dayCelltInfo);
        return (
            <>
                <span >
                    {/* {arg.text} */}
                    نمایش
                    &nbsp;
                    {arg.num}
                    &nbsp;
                    جلسه بیشتر
                </span>
            </>
        )
    }





    renderDayCellContent = (dayCelltInfo) => {
        //console.log(dayCelltInfo);
        return (
            <>
                <span >
                    {new Intl.DateTimeFormat('fa-IR', { month: 'short', day: 'numeric' }).format(dayCelltInfo.date)}
                </span>
            </>
        )
    }


    render() {
        return (
            <>
                <div className="doctors-page">
                    <LoadingPage />
                    <div className="row p-0 m-0 pb-5">

                        <div className="kbh-calendar-container">

                            <FullCalendar
                                initialView="dayGridMonth"
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, bootstrapPlugin]}
                                headerToolbar={{
                                    left: 'prev,next today',
                                    //center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,listWeek'
                                }}
                                buttonText={{
                                    today: 'امروز',
                                    month: 'ماه',
                                    week: 'هفته',
                                    day: 'روز',
                                    list: 'لیست',
                                    // next: 'بعدی',
                                    // prev: 'قبلی'
                                }}
                                buttonIcons={{
                                    close: 'fa-times',
                                    prev: 'chevron-left',
                                    next: 'chevron-right',
                                    prevYear: 'chevrons-left', // double chevron
                                    nextYear: 'chevrons-right' // double chevron
                                }}
                                themeSystem='bootstrap'
                                selectable={true}
                                select={this.handleDateSelect}
                                dateClick={this.handleDateClick}
                                eventClick={this.handleEventClick}
                                eventContent={this.renderEventContent}
                                dayCellContent={this.renderDayCellContent}
                                moreLinkContent={this.renderMoreLinkContent}
                                //dayHeaderContent='some text'
                                events={this.state.eventsList}
                                initialEvents={this.state.eventsList}
                                // contentHeight="calc(100vh - 205px)"
                                locale={faLocale}
                                //showNonCurrentDates={false}
                                fixedWeekCount={false}
                                eventTimeFormat={{
                                    hour: 'numeric',
                                    minute: '2-digit',
                                    meridiem: false
                                }}
                                displayEventTime={true}
                                eventBackgroundColor="#368f96"
                                defaultAllDay={false}
                                dayHeaders={true}
                                dayMaxEvents={2}
                                allDaySlot={false}
                                slotLabelFormat={{
                                    hour: 'numeric',
                                    minute: '2-digit',
                                    meridiem: false
                                }}
                                // contentHeight="calc(100vh - 205px)"
                                height="calc(100vh - 150px)"
                                //aspectRatio={1}
                                //navLinks={true}
                                //weekNumbers={true}
                                expandRows={true}
                            />

                        </div>

                    </div>
                </div>
            </>
        )
    }



}


const map_State_to_Props = state => ({
    accountState: state.accountReducer,
    loadingState: state.loadingReducer
});

const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(MeetingsReserveCalendar));




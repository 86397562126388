import * as axiosRequest from "../../helper/axiosRequest/httpRequest";
import * as profileUrlAddress from "../../utils/urlAddress/profileUrlAddress";

export async function getProfile() {
    return await axiosRequest.HttpPost(profileUrlAddress.getProfile, null);
}

export async function getProfileSummary() {
    return await axiosRequest.HttpPost(profileUrlAddress.getProfileSummary, null);
}

export async function setProfile(user) {
    return await axiosRequest.HttpPost(profileUrlAddress.setProfile, user);
}

export async function setProfileImage(formData) {
    return await axiosRequest.HttpPost_MultiPartContent(profileUrlAddress.setProfileImage, formData);
}

export async function getUserTimeZone(user) {
    return await axiosRequest.HttpPost(profileUrlAddress.getTimeZone, user);
}

export async function setUserTimeZone(user) {
    return await axiosRequest.HttpPost(profileUrlAddress.setTimeZone, user);
}

export async function setPushNotifClientId(user) {
    return await axiosRequest.HttpPost(profileUrlAddress.setPushNotifClientId, user);
}